import { apiConfig, processAction } from "src/https";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const nameReducer = "collectionReducer";
const { apiCollection } = apiConfig;
export interface ICollection {
    id: string;
    title: string;
    description: string;
}

interface IStates {
    collectionById: {
        [id: string]: ICollection;
    };
}

// --- Tạo thunk ---
export const getCollectionById = createAsyncThunk(
    `${nameReducer}/getCollectionById`,
    async (collectionId: string) => {
        const response = await processAction({
            path: apiCollection.getCollection.path + "/" + collectionId,
            method: apiCollection.getCollection.method,
        });
        return response.data as ICollection;
    }
);

const collectionSlice = createSlice({
    name: nameReducer,
    initialState: {
        collectionById: {
            all: {
                id: "all",
                title: "Tất cả",
                description: "",
            },
        },
    } as IStates,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCollectionById.fulfilled, (state, action) => {
            const collection = action.payload;
            state.collectionById[collection.id] = collection;
        });
    },
});

export default collectionSlice.reducer;
