import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Carousel from "src/components/carousel/Carousel";
import CategorySectionCreator from "src/components/CategorySectionCreator";
import Category from "src/components/icons/Category";
import MyCard from "src/components/MyCard";

import CategroyItem from "./CategoryItem";

import categoryBanner1 from "src/assets/images/categorybanner_1_img.webp";
import categoryBanner2 from "src/assets/images/categorybanner_2_img.webp";
import categoryBanner3 from "src/assets/images/categorybanner_3_img.webp";
import categoryBanner4 from "src/assets/images/categorybanner_4_img.webp";
import categoryBanner5 from "src/assets/images/categorybanner_5_img.webp";
import useSpecialTag from "src/hooks/useSpecialTag";
import useWindowSize from "src/hooks/useWindowSize";

const SectionFeaturedCategory = () => {
    const [visibleSlides, setVisibleSlides] = useState(3);
    const { width } = useWindowSize();

    const { data: specialTagData } = useSpecialTag();

    useEffect(() => {
        if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(3);
        else setVisibleSlides(4);
    }, [width]);
    const listFeaturedCategory = [
        {
            image: categoryBanner1,
            backgroundColor: "#faf1ff",
        },
        {
            image: categoryBanner2,
            backgroundColor: "#faf4eb",
        },
        {
            image: categoryBanner3,
            backgroundColor: "#f4e6e5",
        },
        {
            image: categoryBanner4,
            backgroundColor: "#e6f2f4",
        },
        {
            image: categoryBanner5,
            backgroundColor: "#fff6f6",
        },
    ];

    return specialTagData ? (
        <CategorySectionCreator icon={<Category color="primary" />} title={specialTagData.tag_name}>
            <Carousel totalSlides={5} visibleSlides={visibleSlides}>
                {specialTagData.collections.map((collectionSpecialTag, index) => (
                    <MyCard
                        sx={{
                            height: "100%",
                        }}
                        key={index}
                        elevation={0}>
                        <CategroyItem
                            key={index}
                            categoryItem={{
                                image: listFeaturedCategory[index].image,
                                title: collectionSpecialTag.collection_name,
                                id: collectionSpecialTag.collection_id,
                                backgroundColor: listFeaturedCategory[index].backgroundColor,
                            }}></CategroyItem>
                    </MyCard>
                ))}
            </Carousel>
        </CategorySectionCreator>
    ) : null;
};

export default SectionFeaturedCategory;
