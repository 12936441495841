import { localKey } from "src/constants";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getObjectData, setObjectData } from "src/util/localStore";

const nameReducer = "cartReducer";

export type CartItem = {
    id: string;
    name: string;
    productName: string;
    productId: string;
    qty: number;
    price: number;
    imgUrl?: string;
};

interface IStates {
    cartList: CartItem[];
    isOpenMiniCart: boolean;
}

// --- Tạo thunk ---
const cartSlice = createSlice({
    name: nameReducer,
    initialState: {
        cartList: getObjectData(localKey.cartList) || [],
        isOpenMiniCart: false,
    } as IStates,
    reducers: {
        changeCartAmount(state, action: PayloadAction<CartItem>): void | IStates | any {
            let cartList = state.cartList;
            let cartItem = action.payload;
            let exist = cartList.find((item) => item.id === cartItem.id);

            if (cartItem.qty < 1) {
                state.cartList = cartList.filter((item) => item.id !== cartItem.id);
                setObjectData(localKey.cartList, state.cartList);
                return;
            }
            if (exist) {
                state.cartList = cartList.map((item) => {
                    if (item.id === cartItem.id) return { ...item, qty: cartItem.qty };
                    return item;
                });
                setObjectData(localKey.cartList, state.cartList);

                return;
            }

            state.cartList = [...cartList, cartItem];
            setObjectData(localKey.cartList, state.cartList);
        },
        clearCart(state) {
            state.cartList = [];
            setObjectData(localKey.cartList, state.cartList);
        },
        updateIsOpenMiniCart(state, action: PayloadAction<boolean>) {
            state.isOpenMiniCart = action.payload;
        },
    },
});
export const { changeCartAmount, clearCart, updateIsOpenMiniCart } = cartSlice.actions;

export default cartSlice.reducer;
