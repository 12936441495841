import React, { cloneElement, FC, ReactNode } from "react";

import clsx from "clsx";

import { Drawer, styled } from "@mui/material";

export interface SidenavProps {
    position?: "left" | "right";
    open?: boolean;
    width?: number;
    handle: React.ReactElement;
    toggleSidenav?: () => void;
    children: ReactNode;
}

const Wrapper = styled("div")(() => ({
    "& .handle": {
        cursor: "pointer",
    },
}));

const Sidenav: FC<SidenavProps> = ({ position, open, width, handle, children, toggleSidenav }) => {
    return (
        <Wrapper>
            <Drawer
                open={open}
                anchor={position}
                onClose={toggleSidenav}
                SlideProps={{ style: { width: width || 280 } }}>
                {children}
            </Drawer>

            {handle &&
                cloneElement(handle, {
                    className: clsx(handle.props?.className, "handle"),
                    onClick: toggleSidenav,
                })}
        </Wrapper>
    );
};

Sidenav.defaultProps = {
    width: 280,
    position: "left",
    open: false,
};

export default Sidenav;
