import { FC } from "react";

import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Box, Typography } from "@mui/material";

import buildClassName from "src/util/buildClassName";

// import buildClassName from "src/util/buildClassName";

const useStyle = makeStyles()(() => ({
    categoryLink: {
        padding: "40px 16px",
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        "&:hover": {
            boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.1)",

            ".categorylink-btn": {
                i: {
                    marginLeft: "7px",
                },
            },
        },
        "@media (max-width:767px)": {
            padding: "10px",
            "> div": {
                textAlign: "center",
            },
        },
    },
    categoryBlock: {
        position: "relative",
        marginBottom: 0,

        img: {
            maxWidth: "65px",
        },

        h3: {
            fontSize: "18px",
            fontWeight: "700",
            margin: "10px 0 5px",
            a: {
                color: "#252a2b",
            },
            "@media (max-width:767px)": {
                fontSize: "14px",
            },
            "@media (max-width:650px)": {
                fontSize: "10px",
            },
        },
    },
    categoryBtn: {
        marginTop: "auto",
        a: {
            fontSize: "16px",
            color: "#252a2b",
            "@media (max-width:767px)": {
                fontSize: "12px",
            },
            "@media (max-width:650px)": {
                fontSize: "9px",
            },
        },
        i: {
            transition: "all 400ms ease",
            transform: "translateY(2px)",
            position: "relative",
            left: "2px",
        },
    },
}));

type OwnProps = {
    categoryItem: {
        image: string;
        title: string;
        backgroundColor: string;
        id: string;
    };
};
const CategroyItem: FC<OwnProps> = ({ categoryItem }) => {
    const { classes } = useStyle();

    return (
        <Link
            style={{
                backgroundColor: categoryItem.backgroundColor,
            }}
            to={`/collection/${categoryItem.id}`}
            className={classes.categoryLink}>
            <Box className={classes.categoryBlock}>
                <Box>
                    <img src={categoryItem.image} alt="" />
                </Box>
            </Box>
            <Box className={classes.categoryBlock}>
                <Typography variant="h3">{categoryItem.title}</Typography>
            </Box>
            <Box className={buildClassName(classes.categoryBtn, "categorylink-btn")}>
                <Box>
                    <Typography variant="body1">
                        Xem ngay
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Typography>
                </Box>
            </Box>
        </Link>
    );
};

export default CategroyItem;
