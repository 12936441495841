import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ProductResponse } from "src/types/service";
import { formatVND } from "src/util";
import { mappingValueAttributeVariant } from "src/util/common";

import Add from "@mui/icons-material/Add";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import { Alert, Box, Button, IconButton, Snackbar, Stack, styled } from "@mui/material";
import MyCard from "src/components/MyCard";
import MyImage from "src/components/MyImage";
import { H3, H5 } from "src/components/Typography";

import FlexBox from "../FlexBox";
import ProductVariantButton from "../ProductVariantButton";

import noImage from "src/assets/images/no_image.jpg";
import { CartItem, changeCartAmount, updateIsOpenMiniCart } from "src/store/cartReducer";

const StyledBazarCard = styled(MyCard)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",

    "&:hover": {
        "& .css-1i2n18j": {
            display: "flex",
        },
    },
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        display: "block",
    },
}));

const HoverIconWrapper = styled(Box)(({ theme }) => ({
    display: "none",
    flexDirection: "column",
    position: "absolute",
    top: "7px",
    right: "15px",
    cursor: "pointer",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
        display: "flex",
    },
}));

const ContentWrapper = styled(Box)(() => ({
    padding: "1rem",
    "& .title, & .categories": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

const ProductCard1 = ({ product }: { product: ProductResponse }) => {
    const [open, setOpen] = useState(false);

    const [selectedVariant, setSelectedVariant] = useState<ProductResponse["variants"][number]>(
        product.variants[0]
    );
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    const [cartItem, setCartItem] = useState<CartItem | undefined>(undefined);
    let cartList = useSelector((state: any) => state.cart.cartList);

    useEffect(() => {
        setCartItem(cartList.find((item: CartItem) => item.id === product.id));
    }, [cartList, product.id]);
    const dispatch = useDispatch<any>();

    // should refactor product card
    const handleCartAmountChange = useCallback(
        (amount: number) => () => {
            dispatch(
                changeCartAmount({
                    id: selectedVariant.id,
                    name: selectedVariant.attributes.map(mappingValueAttributeVariant).join(" / "),
                    productName: product.name,
                    productId: product.id,
                    qty: amount,
                    price: selectedVariant.retail_price,
                    imgUrl: product.images && product.images[0]?.url,
                })
            );
            setOpen(true);
            dispatch(updateIsOpenMiniCart(true));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedVariant.id]
    );

    const navigate = useNavigate();

    const goToDetail = () => {
        navigate(`/product/${product.id}`);
    };

    return (
        <StyledBazarCard hoverEffect={true}>
            <ImageWrapper
                sx={{
                    flex: 1,
                    alignItems: "center",
                    display: "flex",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}>
                <HoverIconWrapper>
                    <IconButton sx={{ p: "6px" }} onClick={goToDetail}>
                        <RemoveRedEye color="secondary" fontSize="small" />
                    </IconButton>
                </HoverIconWrapper>

                <Box onClick={goToDetail}>
                    <MyImage
                        src={(product?.images && product?.images[0]?.url) || noImage}
                        width={"100%"}
                        height={"auto"}
                        style={{
                            padding: "5px",
                        }}
                        alt={product.name}
                    />
                </Box>
            </ImageWrapper>

            <ContentWrapper>
                <Stack>
                    <FlexBox>
                        <Box flex="1 1 0" minWidth="0px" mr={1}>
                            <Box
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={goToDetail}>
                                <H3
                                    className="title"
                                    fontSize="14px"
                                    textAlign="left"
                                    fontWeight="600"
                                    color="text.secondary"
                                    mb={1}
                                    title={product.name}>
                                    {product.name}
                                </H3>
                            </Box>
                            <Link to={`/product/${product?.vendor?.id}`}>
                                <H5
                                    className="title"
                                    fontSize="12px"
                                    textAlign="left"
                                    fontWeight="600"
                                    color="text.warning"
                                    mb={1}
                                    title={product?.vendor?.name}>
                                    {product?.vendor?.name}
                                </H5>
                            </Link>

                            <FlexBox
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                    },
                                }}
                                onClick={goToDetail}
                                alignItems="center"
                                mt={0.5}>
                                <Box pr={1} fontWeight="600" color="primary.main">
                                    {formatVND(selectedVariant.retail_price || 0)}
                                </Box>
                            </FlexBox>
                        </Box>

                        <FlexBox
                            className="add-cart"
                            flexDirection="column-reverse"
                            alignItems="center"
                            justifyContent={"space-between"}
                            width="30px">
                            <Button
                                onClick={handleCartAmountChange((cartItem?.qty || 0) + 1)}
                                variant="outlined"
                                color="primary"
                                sx={{ padding: "3px" }}>
                                <Add fontSize="small" />
                            </Button>
                        </FlexBox>
                    </FlexBox>
                    <Stack direction={"row"} flexWrap={"wrap"} gap={1} pt={1}>
                        {product.variants.map((pVariant) => (
                            <ProductVariantButton
                                key={pVariant.id}
                                variant={pVariant}
                                onClick={() => {
                                    if (pVariant.id !== selectedVariant.id) {
                                        setSelectedVariant(pVariant);
                                    }
                                }}
                                size="small"
                                isSelected={selectedVariant.id === pVariant.id}
                            />
                        ))}
                    </Stack>
                </Stack>
            </ContentWrapper>

            {/* <Dialog open={open} maxWidth={false} onClose={toggleDialog}>
                <DialogContent sx={{ paddingBottom: "1.25rem" }}>
                    <ProductIntro imgUrl={[imgUrl]} title={title} price={price} />
                    <IconButton
                        sx={{ position: "absolute", top: "0", right: "0" }}
                        onClick={toggleDialog}>
                        <Close className="close" fontSize="small" color="primary" />
                    </IconButton>
                </DialogContent>
            </Dialog> */}
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={2000}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Thêm vào giỏ hàng thành công
                </Alert>
            </Snackbar>
        </StyledBazarCard>
    );
};

export default ProductCard1;
