import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { modalType, phoneRegExp } from "src/constants";
import { CheckoutFormValuesProps } from "src/constants/type";
import { formatVND } from "src/util";
import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";
import { Card, Container, Divider, Grid } from "@mui/material";
import FlexBox from "src/components/FlexBox";
import ProductCard2 from "src/components/product-cards/ProductCard2";
import { Span } from "src/components/Typography";

import FormCheckout from "./FormCheckout";

import { yupResolver } from "@hookform/resolvers/yup";
import useCreateOrder from "src/hooks/useCreateOrder";
import useModal from "src/hooks/useModal";
import { CartItem, clearCart } from "src/store/cartReducer";

const FormSchema = Yup.object().shape({
    phone_number: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(phoneRegExp, "Số điện thoại không hợp lệ"),
    last_name: Yup.string().required("Vui lòng nhập họ"),
    first_name: Yup.string().required("Vui lòng nhập tên"),
    address: Yup.string().required("Vui lòng nhập địa chỉ"),
});

const Cart = () => {
    const { mutate: onCreate } = useCreateOrder({
        onSuccess: () => {
            onPush({
                name: modalType.checkoutSuccess,
            });
            dispatch(clearCart());
            reset();
        },
    });
    const { onPush } = useModal();
    const dispatch = useDispatch();

    let cartList = useSelector((state: any) => state.cart.cartList);
    const getTotalPrice = () => {
        return (
            cartList.reduce(
                (accumulator: number, item: CartItem) => accumulator + item.price * item.qty,
                0
            ) || 0
        );
    };
    const onSubmit = async (data: CheckoutFormValuesProps) => {
        const variant_orders = cartList.map((c: CartItem) => ({
            variant_id: c.id,
            quantity: c.qty,
        }));

        const dataSave = { ...data, variant_orders, final_price: getTotalPrice() };
        onCreate(dataSave);
    };
    const {
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<CheckoutFormValuesProps>({
        mode: "onTouched",
        resolver: yupResolver(FormSchema),
        defaultValues: {
            variant_orders: [],
            phone_number: "",
            last_name: "",
            first_name: "",
            address: "",
            payment_method: "COD",
            additional_price: 0,
            shipping_price: 0,
            final_price: 0,
            note: "",
        },
    });

    return (
        <Container sx={{ my: "2rem", pb: "2rem" }}>
            <Grid container spacing={3}>
                <Grid item lg={8} md={8} xs={12}>
                    {cartList.map((item: CartItem) => (
                        <ProductCard2 key={item.id} product={item} />
                    ))}
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Card
                        sx={{
                            padding: "1.5rem 1.75rem",
                            "@media only screen and (max-width: 678px)": {
                                padding: "1rem",
                            },
                        }}>
                        <FlexBox justifyContent="space-between" alignItems="center" mb={2}>
                            <Span color="grey.600">Tổng cộng:</Span>
                            <FlexBox alignItems="flex-end">
                                <Span fontSize="18px" fontWeight="600" lineHeight="1">
                                    {formatVND(getTotalPrice())}
                                </Span>
                            </FlexBox>
                        </FlexBox>
                        <Divider sx={{ mb: "1rem" }} />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormCheckout control={control} />

                            <Divider sx={{ mb: "1rem" }} />

                            <LoadingButton
                                sx={{
                                    width: "100%",
                                    background: (theme) => theme.palette.primary.main,
                                    color: "#fff",
                                    "&:hover": {
                                        background: "#E3364E",
                                    },
                                }}
                                type="submit"
                                size="large"
                                variant="contained"
                                loading={isSubmitting}>
                                Mua hàng
                            </LoadingButton>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Cart;
