import { Box, BoxProps } from "@mui/system";

// eslint-disable-next-line react/prop-types
const FlexBox: React.FC<BoxProps> = ({ children, ...props }) => (
    <Box display="flex" {...props}>
        {children}
    </Box>
);

FlexBox.defaultProps = {
    display: "flex",
};

export default FlexBox;
