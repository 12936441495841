import React, { useEffect, useState } from "react";

import { apiConfig, processAction } from "src/https";
import { ProductResponse } from "src/types/service";
import { buildQueryString } from "src/util";

import { Box, Grid } from "@mui/material";
import ProductCard1 from "src/components/product-cards/ProductCard1";

import CategorySectionHeader from "../CategorySectionHeader";

import useWindowSize from "src/hooks/useWindowSize";

export interface RecentProductsProps {
    productIds: string[];
}

const RecentProducts: React.FC<RecentProductsProps> = ({ productIds }) => {
    const [listProducts, setListProduct] = useState<ProductResponse[]>();
    const [visibleSlides, setVisibleSlides] = useState(3);
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < 500) setVisibleSlides(1);
        else if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(3);
        else setVisibleSlides(4);
    }, [width]);
    const { apiProduct } = apiConfig;
    const query = buildQueryString({
        limit: 8,
        offset: 0,
        product_ids: JSON.stringify(productIds),
    });

    useEffect(() => {
        processAction({
            path: apiProduct.getProduct.path + query,
            method: apiProduct.getProduct.method,
        }).then((res: any) => {
            const { items } = res.data;
            setListProduct(items);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productIds]);
    return (
        <Box mb={7.5}>
            <CategorySectionHeader title="Các sản phẩm đã xem" />
            <Grid container spacing={3}>
                {listProducts?.map((product, ind) => (
                    <Grid item lg={3} md={4} sm={6} xs={6} key={ind}>
                        {product ? <ProductCard1 product={product} /> : null}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default RecentProducts;
