import React from "react";

import { ProductResponse } from "src/types/service";

import { Grid } from "@mui/material";
import ProductCard1 from "src/components/product-cards/ProductCard1";

export interface ProductCard1ListProps {
    listProducts: ProductResponse[];
}

const ProductCard1List: React.FC<ProductCard1ListProps> = ({ listProducts }) => {
    return (
        <div>
            <Grid container spacing={3}>
                {listProducts?.map((product, ind) => (
                    <Grid item lg={4} sm={6} xs={12} key={ind + "_" + product.id}>
                        <ProductCard1 product={product} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ProductCard1List;
