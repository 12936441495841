import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const ReturnPolicy = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Chính Sách Đổi Trả Hàng</Typography>
                            <Typography variant="body1">
                                Để nâng cao chất lượng dịch vụ và trải nghiệm mua sắm của khách
                                hàng, Nhà Sách Kiên Giang chúng tôi có những chính sách phù hợp khi
                                khách hàng có nhu cầu đổi/ trả/ hoàn tiền sản phẩm. Chúng tôi luôn
                                coi trọng và bảo vệ quyền lợi của người tiêu dùng với mong muốn mang
                                đến cho quý khách chất lượng phục vụ tốt nhất
                            </Typography>
                            <Typography variant="h6">1. Đối Với Khách Hàng Mua Online</Typography>
                            <Typography variant="body1">
                                Hướng dẫn các bước đổi/ trả hàng
                            </Typography>
                            <Typography variant="body1">
                                - Bước 1: Vui lòng liên hệ hotline 02973 812 868 để thông báo việc
                                yêu cầu đổi/ trả hàng
                            </Typography>
                            <Typography variant="body1">
                                Quý khách hàng vui lòng kiểm tra thường xuyên để cập nhật những thay
                                đổi của chúng tôi.
                            </Typography>
                            <Typography variant="body1">
                                - Bước 2: Nhân viên chăm sóc khách hàng sẽ liên hệ với bạn để xác
                                nhận; kiểm tra & tiếp nhận hàng được yêu cầu đổi/ trả hàng (chỉ áp
                                dụng đối với các trường hợp đổi/ trả hàng do lỗi xuất phát từ chúng
                                tôi)
                            </Typography>
                            <Typography variant="body1">
                                (Trường hợp đổi hàng theo nhu cầu (màu sắc, kích thước...) bạn vui
                                lòng liên hệ 02973 812 868 để được hướng dẫn cụ thể)
                            </Typography>
                            <Typography variant="body1">
                                - Bước 3: Khi yêu cầu đổi trả được giải quyết, quý khách vui lòng
                                gửi sản phẩm như hiện trạng khi nhận hàng ban đầu về địa chỉ văn
                                phòng công ty nhà sách Kiên Giang bao gồm sản phẩm và đầy đủ phụ
                                kiện, giấy tờ chứng từ kèm theo (nếu có)
                            </Typography>
                            <Typography variant="body1">
                                Nhà sách sẽ giao sản phẩm thay thế hoặc tiến hành hoàn tiền (trường
                                hợp trả hàng) trong vòng 5-7 ngày tùy khu vực kể từ ngày xác nhận
                                việc đổi/ trả hàng.
                            </Typography>
                            <Typography variant="h6">
                                2. Đối Với Khách Hàng Mua Tại Nhà Sách
                            </Typography>
                            <Typography variant="body1">
                                Khi vào web của chúng tôi, khách hàng phải đẩm bảo đủ 18 tuổi, hoặc
                                truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp.
                                Khách hàng đảm bảo có đầy đủ hành vi dân sự để thực hiện các giao
                                dịch mua bán hàng hóa theo quy định hiện hành của pháp luật Việt Nam
                            </Typography>
                            <Typography variant="body1">
                                Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng cáo
                                từ website. Nếu không muốn tiếp tục nhận mail, quý khách có thể từ
                                chối bằng cách nhấp vào đường link ở dưới cùng trong mọi email quảng
                                cáo
                            </Typography>
                            <Typography variant="body1">
                                - Đổi trả trong vòng 7 ngày kể từ ngày mua
                            </Typography>
                            <Typography variant="body1">
                                - Chỉ áp dụng đối với sản phẩm bị lỗi kỹ thuật
                            </Typography>
                            <Typography variant="body1">
                                - Sản phẩm đổi/trả phải kèm hóa đơn tính tiền hoặc hóa đơn VAT
                            </Typography>
                            <Typography variant="body1">
                                - Giá trị sản phẩm đổi phải bằng hoặc cao hơn giá trị sản phẩm đã
                                mua
                            </Typography>
                            <Typography variant="body1">
                                - Sản phẩm phải còn mới, nguyên vẹn, không trầy xước, dơ, nứt, gãy
                                và đầy đủ phụ kiện bao bì, quà tặng khuyến mãi (nếu có)
                            </Typography>
                            <Typography variant="h6">
                                3. Các trường hợp được đổi/ trả hàng/ hoàn tiền
                            </Typography>
                            <Typography variant="body1">Trường hợp mua online</Typography>
                            <Typography variant="body1">
                                - Giao nhầm sản phẩm: tựa sách, hình thức bìa, giá bìa, v.v…,
                            </Typography>
                            <Typography variant="body1">
                                - Hàng hoá không đúng như mô tả trên website (màu sắc, kiểu dáng,
                                chức năng) hoặc hàng hóa bị hư hỏng trong quá trình giao hàng.
                            </Typography>
                            <Typography variant="body1">
                                - Đơn hàng đã thanh toán trước nhưng sản phẩm của đơn hàng đã hết
                                trên toàn bộ hệ thống Nhà Sách Kiên Giang
                            </Typography>
                            <Typography variant="body1">Trường hợp mua tại nhà sách</Typography>-
                            Sản phẩm bị lỗi do in ấn, kỹ thuật
                            <Typography variant="body1">- Phát hiện hàng giả, hàng nhái</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ReturnPolicy;
