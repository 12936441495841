import React from "react";

import { Card } from "@mui/material";
import { Box } from "@mui/system";

export interface ShopIntroCardProps {}

const CollectionIntroCard: React.FC<ShopIntroCardProps> = () => {
    return (
        <Card sx={{ mb: "32px" }}>
            <Box
                height="202px"
                sx={{
                    height: "202px",
                    background:
                        "url(https://theme.hstatic.net/200000427867/1000902683/14/collection_banner.jpg?v=6) center/cover",
                }}
            />
        </Card>
    );
};

export default CollectionIntroCard;
