import axios from "axios";
import { BASE_URL } from "src/constants";

axios.defaults.withCredentials = false;

let client = axios.create({
    withCredentials: false as boolean,
    baseURL: BASE_URL as string,
    timeout: (30 * 1000) as number,
});

const http = {
    get(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                client
                    .get(url)
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(error.response.data.error);
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    getNoRejectError(url: string) {
        return new Promise((resolve, reject) => {
            try {
                client
                    .get(url, {})
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response && error.response.data) {
                            if (error.response.data.error) {
                                return reject(error.response.data.error);
                            }
                            return reject(error.response.data.message);
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    post(url: string, data?: object): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                client
                    .post(url, data)
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(error.response.data.error);
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    put(url: string, data?: object): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                client
                    .put(url, data)
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(new Error(error.response.data.error.message));
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    delete(url: string, data: object): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                client
                    .delete(url, {
                        data,
                    })
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(new Error(error.response.data.error.message));
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    postForm(url: string, formData: object) {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
            try {
                // eslint-disable-next-line no-promise-executor-return
                return client
                    .post(url, formData, {
                        // todo: check header will be merged or overrided with header set by setHeaders function !?
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(new Error(error.response.data.error.message));
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },
    putForm(url: string, formData: object) {
        // eslint-disable-next-line consistent-return
        return new Promise((resolve, reject) => {
            try {
                // eslint-disable-next-line no-promise-executor-return
                return client
                    .put(url, formData, {
                        // todo: check header will be merged or overrided with header set by setHeaders function !?
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((resp: any) => {
                        const { data } = resp;
                        if (!data.error) {
                            resolve(resp);
                        } else {
                            reject(data.error);
                        }
                    })
                    .catch((error: any) => {
                        if (error.response.data) {
                            if (error.response.data.error) {
                                return reject(new Error(error.response.data.error.message));
                            }
                            return reject(new Error(error.response.data.message));
                        }
                        return reject(error);
                    });
            } catch (e) {
                reject(e);
            }
        });
    },

    setHeaders(object: object) {
        const headers = (client.defaults && client.defaults.headers) || {};
        client.defaults.headers = Object.assign(headers, object);
    },
};

export default http;
