export const setObjectData = (key: string, data: object) => {
    window.localStorage.setItem(key, JSON.stringify(data));
};

export const getObjectData = (key: string) => {
    const jsonData = window.localStorage.getItem(key);
    if (jsonData) {
        try {
            return JSON.parse(jsonData);
        } catch {
            return false;
        }
    }
    return false;
};

export const setStringData = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
};

export const getStringData = (key: string): string | null => {
    return window.localStorage.getItem(key);
};

export const removeItem = (key: string) => {
    window.localStorage.removeItem(key);
};
