import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Chính Sách Bảo Mật</Typography>

                            <Typography variant="body1">
                                Việc bảo lưu thông tin cá nhân của quý khách nhằm giúp chúng tôi có
                                điều kiện nâng cao chất lượng dịch vụ để phục vụ quý khách hàng ngày
                                một tốt hơn. nhasachkiengiang.com cam kết sử dụng thông tin khách
                                hàng một cách hợp lý và bảo mật nhất.
                            </Typography>

                            <Typography variant="h6">
                                1. Về Việc Bảo Lưu Thông Tin Khách Hàng
                            </Typography>
                            <Typography variant="body1">
                                Để sử dụng và trải nghiệm các dịch vụ của Nhà sách Kiên Giang, bạn
                                cần đăng ký tài khoản và cung cấp một số thông tin như: email, họ
                                tên, số điện thoại, địa chỉ và một số thông tin khác. Bạn có thể tùy
                                chọn không cung cấp cho chúng tôi một số thông tin nhất định nhưng
                                sẽ có một chút bất tiện vì khi đó, bạn sẽ không thể được hưởng một
                                số tiện ích mà những tính năng của chúng tôi cung cấp.
                            </Typography>
                            <Typography variant="body1">
                                Khi quý khách hàng truy cập vào trang website của chúng tôi có nghĩa
                                là quý khách đồng ý với các điều khoản này. Trang web có quyền thay
                                đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Điều khoản
                                mua bán hàng hóa này, vào bất cứ lúc nào. Các thay đổi có hiệu lực
                                ngay khi được đăng trên trang web mà không cần thông báo trước. Và
                                khi quý khách tiếp tục sử dụng trang web, sau khi các thay đổi về
                                Điều khoản này được đăng tải, có nghĩa là quý khách chấp nhận với
                                những thay đổi đó.
                            </Typography>
                            <Typography variant="body1">
                                Mọi thông tin bạn nhập trên website sẽ được lưu trữ để sử dụng cho
                                mục đích phản hồi yêu cầu của khách hàng, đưa ra những gợi ý phù hợp
                                cho từng khách hàng khi mua sắm tại website, nâng cao chất lượng
                                hàng hóa dịch vụ và liên lạc với khách hàng khi cần thiết.
                            </Typography>

                            <Typography variant="h6">2. Mục Đích Sử Dụng Thông Tin</Typography>
                            <Typography variant="body1">
                                Mục đích của việc bảo lưu thông tin là nhằm xây dựng
                                nhasachkiengiang.com trở thành một website bán hàng trực tuyến mang
                                lại nhiều tiện ích nhất cho khách hàng. Vì thế, việc sử dụng thông
                                tin sẽ phục vụ những hoạt động sau:
                            </Typography>
                            <Typography variant="body1">
                                - Gửi newsletter giới thiệu sản phẩm mới và những chương trình
                                khuyến mãi của Nhà sách Kiên Giang.
                            </Typography>
                            <Typography variant="body1">
                                - Cung cấp một số tiện ích, dịch vụ hỗ trợ khách hàng.
                            </Typography>
                            <Typography variant="body1">
                                - Nâng cao chất lượng dịch vụ khách hàng của Nhà sách Kiên Giang.
                            </Typography>
                            <Typography variant="body1">
                                - Làm cơ sở giải quyết các vấn đề khiếu nại, tranh chấp phát sinh
                                liên quan đến việc sử dụng sản phẩm, dịch vụ tại website Nhà sách
                                Kiên Giang.
                            </Typography>
                            <Typography variant="body1">
                                - Ngăn chặn những hoạt động vi phạm pháp luật Việt Nam
                            </Typography>

                            <Typography variant="h6">3. Chia Sẻ Thông Tin</Typography>
                            <Typography variant="body1">
                                Chúng tôi sẽ không chia sẻ thông tin của bạn trừ những trường hợp cụ
                                thể sau đây:
                            </Typography>
                            <Typography variant="body1">
                                - Để bảo vệ Nhà sách Kiên Giang và các bên thứ ba khác: Chúng tôi
                                chỉ đưa ra thông tin tài khoản và những thông tin cá nhân khác khi
                                tin chắc rằng việc đưa những thông tin đó là phù hợp với luật pháp,
                                bảo vệ quyền lợi, tài sản của người sử dụng dịch vụ, của Nhà sách
                                Kiên Giang và các bên thứ ba khác.
                            </Typography>
                            <Typography variant="body1">
                                - Theo yêu cầu pháp lý từ một cơ quan chính phủ hoặc khi chúng tôi
                                tin rằng việc làm đó là cần thiết và phù hợp nhằm tuân theo các yêu
                                cầu pháp lý.
                            </Typography>
                            <Typography variant="body1">
                                - Trong những trường hợp còn lại, chúng tôi sẽ có thông báo cụ thể
                                cho bạn khi phải tiết lộ thông tin cho một bên thứ ba và thông tin
                                này chỉ được cung cấp khi được sự phản hồi đồng ý từ phía bạn.
                            </Typography>

                            <Typography variant="h6">
                                4. Chính Sách Cam Kết Bảo Mật Thông Tin Khách Hàng
                            </Typography>
                            <Typography variant="body1">
                                Chúng tôi cam kết không tiết lộ thông tin khách hàng, không bán hoặc
                                chia sẻ thông tin khách hàng của Nhà sách Kiên Giang cho bên thứ ba
                                nào khác vì mục đích thương mại.
                            </Typography>
                            <Typography variant="body1">
                                Chúng tôi cam kết mọi thông tin thanh toán giao dịch trực tuyến của
                                khách hàng đều được bảo mật và an toàn. Các thông tin tài khoản ngân
                                hàng, thông tin thẻ tín dụng hay thông tin tài chính đều không bị
                                lưu lại dưới bất kỳ hình thức nào.
                            </Typography>
                            <Typography variant="body1">
                                Quý khách không nên trao đổi những thông tin cá nhân và thông tin
                                thanh toán của mình cho bên thứ ba nào khác để tránh rò rỉ thông
                                tin. Khi sử dụng chung máy tính với nhiều người, vui lòng thoát khỏi
                                tài khoản sau khi sử dụng dịch vụ của website chúng tôi để tự bảo vệ
                                thông tin về mật khẩu truy cập của mình.
                            </Typography>
                            <Typography variant="body1">
                                Nhà sách Kiên Giang hiểu rằng quyền lợi của bạn trong việc bảo vệ
                                thông tin cá nhân cũng chính là trách nhiệm của chúng tôi nên trong
                                bất kỳ trường hợp có thắc mắc, góp ý nào liên quan đến chính sách
                                bảo mật của Nhà sách Kiên Giang, vui lòng liên hệ với chúng tôi qua
                                số điện thoại XXX.XXX.XXXX để được phúc đáp, giải quyết thắc mắc
                                trong thời gian sớm nhất.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;
