import React, { useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import Apps from "@mui/icons-material/Apps";
import FilterList from "@mui/icons-material/FilterList";
import ViewList from "@mui/icons-material/ViewList";
import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "src/components/FlexBox";
import ProductCardGridList from "src/components/products/ProductCardGridList";
import ProductFilterCard from "src/components/products/ProductFilterCard";
import Sidenav from "src/components/sidenav/Sidenav";
import ProductCardSkeleton from "src/components/skeleton/ProductCardSkeleton";
import { H5, Paragraph } from "src/components/Typography";

import useHandleQueryProduct from "src/hooks/useHandleQueryProduct";
import useWindowSize from "src/hooks/useWindowSize";
import { IProduct } from "src/store/productReducer";

const ProductSearchResult = () => {
    const [view, setView] = useState("grid");
    const { width } = useWindowSize();
    const isTablet = width < 1024;

    const toggleView = useCallback(
        (v: string) => () => {
            setView(v);
        },
        []
    );

    let { name } = useParams();
    const {
        listProducts,
        filterQuery,
        total,
        canLoadMore,
        isLoadingMore,
        isLoading,
        loadMore,
        handleChangePrice,
        handleChangeVendors,
        isShowClearFilter,
        clearFilter,
    } = useHandleQueryProduct({
        name: name,
    });

    return (
        <Container sx={{ my: "2rem", pb: "2rem" }}>
            <Box pt={2.5}>
                <Card
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: "55px",
                        p: {
                            xs: "1.25rem 1.25rem 0.25rem",
                            sm: "1rem 1.25rem",
                            md: "0.5rem 1.25rem",
                        },
                    }}
                    elevation={1}>
                    <div>
                        <H5>
                            Kết quả tìm kiếm cho <b>"{name}"</b>.
                        </H5>
                        <Paragraph color="grey.600">
                            Có <b>{total} sản phẩm</b> cho tìm kiếm
                        </Paragraph>
                    </div>
                    <FlexBox alignItems="center" flexWrap="wrap" my="0.5rem">
                        <FlexBox alignItems="center" my="0.25rem">
                            <Paragraph color="grey.600" mr={1}>
                                Chế độ xem:
                            </Paragraph>
                            <IconButton onClick={toggleView("grid")}>
                                <Apps
                                    color={view === "grid" ? "primary" : "inherit"}
                                    fontSize="small"
                                />
                            </IconButton>

                            {!!isTablet && (
                                <Sidenav
                                    handle={
                                        <IconButton>
                                            <FilterList fontSize="small" />
                                        </IconButton>
                                    }>
                                    <ProductFilterCard
                                        selectedVendors={filterQuery.vendor_ids}
                                        selectedPrice={{
                                            from: filterQuery.from_price,
                                            to: filterQuery.to_price,
                                        }}
                                        onChangeVendors={handleChangeVendors}
                                        onChangePrice={handleChangePrice}
                                    />
                                    {isShowClearFilter && (
                                        <Box
                                            component={LoadingButton}
                                            variant="outlined"
                                            loading={isLoadingMore}
                                            sx={{
                                                minWidth: "160px",
                                                maxWidth: "80%",
                                                position: "relative",
                                                transform: "translateX(-50%)",
                                                left: "50%",
                                            }}
                                            color={(theme) => theme.palette.primary.main}
                                            size="small"
                                            marginTop={2}
                                            height={54}
                                            onClick={clearFilter}>
                                            Xoá bộ lọc
                                        </Box>
                                    )}
                                </Sidenav>
                            )}
                        </FlexBox>
                    </FlexBox>
                </Card>

                <Grid container spacing={3}>
                    <Grid
                        item
                        lg={3}
                        xs={12}
                        sx={{
                            "@media only screen and (max-width: 1024px)": {
                                display: "none",
                            },
                        }}>
                        <ProductFilterCard
                            selectedVendors={filterQuery.vendor_ids}
                            selectedPrice={{
                                from: filterQuery.from_price,
                                to: filterQuery.to_price,
                            }}
                            onChangeVendors={handleChangeVendors}
                            onChangePrice={handleChangePrice}
                        />
                        {isShowClearFilter && (
                            <Box
                                component={LoadingButton}
                                variant="outlined"
                                loading={isLoadingMore}
                                sx={{
                                    minWidth: "160px",
                                    maxWidth: "80%",
                                    position: "relative",
                                    transform: "translateX(-50%)",
                                    left: "50%",
                                }}
                                color={(theme) => theme.palette.primary.main}
                                size="small"
                                marginTop={2}
                                height={54}
                                onClick={clearFilter}>
                                Xoá bộ lọc
                            </Box>
                        )}
                    </Grid>

                    <Grid item lg={9} xs={12}>
                        {view === "grid" && isLoading ? (
                            <Grid container spacing={3}>
                                {[...Array(12)].map((_, index) => (
                                    <Grid item lg={4} sm={6} xs={12} key={index}>
                                        <ProductCardSkeleton key={index} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <ProductCardGridList listProducts={listProducts as IProduct[]} />
                        )}
                        {canLoadMore && (
                            <Box
                                component={LoadingButton}
                                variant="outlined"
                                loading={isLoadingMore}
                                sx={{
                                    position: "relative",
                                    transform: "translateX(-50%)",
                                    left: "50%",
                                }}
                                color={(theme) => theme.palette.primary.main}
                                size="small"
                                marginTop={2}
                                height={54}
                                onClick={loadMore}>
                                Xem thêm sản phẩm
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ProductSearchResult;
