import { ApiConfig } from "./type";

export const apiConfig: ApiConfig = {
    apiProduct: {
        getProduct: {
            path: "/products",
            method: "get",
        },
    },
    apiCollection: {
        getCollection: {
            path: "/collections",
            method: "get",
        },
    },
    apiMenu: {
        getAllMenu: {
            path: "/menus",
            method: "get",
        },
    },
    apiVendor: {
        getAllVendor: {
            path: "/vendors",
            method: "get",
        },
    },
};

export default apiConfig;
