import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Box, Container, Grid } from "@mui/material";

import buildClassName from "src/util/buildClassName";

const useStyles = makeStyles()((theme) => ({
    logImg: {
        maxWidth: "150px",
    },
    ulInfo: {
        listStyle: "none",
        paddingLeft: 0,
        li: {
            marginBottom: "3px",
            "&:before": {
                content: '"- "',
            },
        },
    },
    facebookIcon: {
        width: "32px",
        height: "32px",
        lineHeight: "30px",
        fontSize: "14px",
        backgroundColor: "transparent",
        border: "1px solid #333333",
        color: "#333333",
        display: "block",
        marginRight: "8px",
        marginBottom: "8px",
        textAlign: "center",
        borderRadius: "4px",
        transition: "0.4s",

        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",
            border: theme.palette.primary.main,
        },
    },
    titleFooter: {
        position: "relative",
        fontSize: "16px",
        color: "#333333",
        margin: "0px",
        padding: "15px 0",
        fontWeight: 500,
        [theme.breakpoints.up("md")]: {
            fontWeight: 700,
            fontSize: "18px",
            paddingBottom: "12px",
        },
    },
    contentFooter: {
        paddingBlock: "10px 0 20px",
    },
    addressFooter: {
        ul: {
            listStyle: "none",
            paddingLeft: 0,

            li: {
                margin: "0 0 8px",
            },
        },
    },
    footerNavLink: {
        padding: 0,
        margin: 0,
        listStyleType: "none",

        li: {
            paddingLeft: "15px",
            position: "relative",
            margin: "0 0 8px",

            a: {
                color: "#333333",
                textDecoration: "none",
                outline: "none",
                transition: "all 0.3s ease-in-out",

                "&:hover": {
                    color: theme.palette.primary.main,

                    "&:before": {
                        background: theme.palette.primary.main,
                    },
                },

                "&:before": {
                    content: '""',
                    width: "4px",
                    height: "4px",
                    background: "#333333",
                    left: 0,
                    opacity: 1,
                    position: "absolute",
                    top: 0,
                    borderRadius: "50%",
                    bottom: 0,
                    margin: "auto",
                },
            },
        },
    },
}));

const MHeader = () => {
    const { classes } = useStyles();

    return (
        <Box
            sx={{
                backgroundColor: "#fff",
            }}>
            <Container sx={{ p: "1rem" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <div className="ft-logo">
                            <Link to="https://nhasachkiengiang.com">
                                <img
                                    src="//theme.hstatic.net/200000507471/1000858963/14/logo-footer.png?v=337"
                                    alt="Nhà Sách Kiên Giang"
                                    className={buildClassName("img-responsive", classes.logImg)}
                                />
                            </Link>
                        </div>
                        <Box style={{ marginTop: 0 }}>
                            <ul className={classes.ulInfo}>
                                <li>
                                    Chào mừng bạn đến Nhà Sách Kiên Giang. nhasachkiengiang.com nhận
                                    đặt hàng trực tuyến và giao hàng tận nơi.
                                </li>
                                <li>
                                    Công Ty TNHH Hỗ Trợ &amp; Phát Triển Giáo dục Nhà Sách Kiên
                                    Giang.
                                </li>
                                <li>
                                    Giấy phép kinh doanh số: 1701662099 do Sở Kế Hoạch và Đầu Tư
                                    Tỉnh Kiên Giang cấp ngày 05/09/2018.
                                </li>
                                <li>GĐ/ Sỡ hữu website: Nguyễn Công Tú</li>
                            </ul>
                        </Box>

                        <Box className="logo-footer">
                            <Link
                                to="http://online.gov.vn/Home/WebDetails/94912"
                                target="_blank"
                                rel="noreferrer">
                                <img
                                    className={buildClassName(classes.logImg)}
                                    data-src="//theme.hstatic.net/200000507471/1000858963/14/footer_logobct_img.png?v=337"
                                    src="//theme.hstatic.net/200000507471/1000858963/14/footer_logobct_img.png?v=337"
                                    alt="Bộ Công Thương"
                                />
                            </Link>
                        </Box>

                        <ul
                            className="footerNav-social"
                            style={{ marginTop: "16px", listStyle: "none", paddingLeft: 0 }}>
                            <li>
                                <Link
                                    to="https://www.facebook.com/Nh%C3%A0-s%C3%A1ch-Ki%C3%AAn-Giang-1692913577631156"
                                    className={buildClassName(classes.facebookIcon)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Facebook"
                                    aria-label="Facebook">
                                    <i className="fa fa-facebook"></i>
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h4 className={classes.titleFooter}>Thông tin liên hệ</h4>
                        <div className={buildClassName("content-footer", classes.contentFooter)}>
                            <div className={classes.addressFooter}>
                                <ul>
                                    <li className="contact-1">
                                        <b>Địa chỉ:</b> 93-95-97, Đường 3 Tháng 2, Phường Vĩnh Bảo,
                                        Thành Phố Rạch Giá, Tỉnh Kiên Giang
                                    </li>
                                    <li className="contact-2">
                                        <b>Điện thoại</b> (Làm việc từ 7h00 - 21h00): 02973.812.868
                                        - 0919.681.768
                                    </li>
                                    <li className="contact-3">
                                        <b>Fax:</b> 02973.812.868
                                    </li>
                                    <li className="contact-4">
                                        <b>Email: </b>nskg.hotro@gmail.com <br /> Quý khách hàng có
                                        thể phản hồi về thông tin về chất lượng hàng hóa và dịch vụ
                                        qua email <b> nskg.hotro@gmail.com </b>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <h4 className={classes.titleFooter}>DỊCH VỤ - HỖ TRỢ</h4>
                        <div className={buildClassName("content-footer", classes.contentFooter)}>
                            <ul className={classes.footerNavLink}>
                                <li className="item">
                                    <Link to="/pages/lien-he" title="Liên Hệ">
                                        Liên Hệ
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/pages/thong-tin-huong-dan-thanh-toan"
                                        title="Hướng Dẫn Thanh Toán">
                                        Hướng Dẫn Thanh Toán
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/pages/chinh-sach-doi-tra"
                                        title="Chính Sách Đổi Trả - Hoàn Tiền">
                                        Chính Sách Đổi Trả - Hoàn Tiền
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/pages/chinh-sach-bao-mat" title="Chính Sách Bảo Mật">
                                        Chính Sách Bảo Mật
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/pages/dieu-khoan-dich-vu" title="Điều Khoản Dịch Vụ">
                                        Điều Khoản Dịch Vụ
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/pages/phuong-thuc-van-chuyen"
                                        title="Chính Sách Giao Hàng &amp; Kiểm Hàng">
                                        Chính Sách Giao Hàng &amp; Kiểm Hàng
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/pages/khieu-nai-gop-y" title="Khiếu Nại - Góp Ý">
                                        Khiếu Nại - Góp Ý
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h4 className={classes.titleFooter}>SẢN PHẨM </h4>
                        <div className={buildClassName("content-footer", classes.contentFooter)}>
                            <ul className={classes.footerNavLink}>
                                <li className="item">
                                    <Link to="/collections/sach-tieng-viet" title="Sách Tiếng Việt">
                                        Sách Tiếng Việt
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/collections/sach-giao-khoa"
                                        title="Sách Giáo Khoa- Tham Khảo">
                                        Sách Giáo Khoa- Tham Khảo
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/collections/vpp-dung-cu-hoc-sinh"
                                        title="VPP- Dụng Cụ Học Sinh">
                                        VPP- Dụng Cụ Học Sinh
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/collections/do-dung-hoc-sinh"
                                        title="Đồ Dùng Học Sinh">
                                        Đồ Dùng Học Sinh
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/collections/do-choi-1" title="Đồ Chơi">
                                        Đồ Chơi
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/collections/qua-luu-niem" title="Quà Lưu Niệm">
                                        Quà Lưu Niệm
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link to="/collections/bach-hoa-pham" title="Bách Hóa Phẩm">
                                        Bách Hóa Phẩm
                                    </Link>
                                </li>

                                <li className="item">
                                    <Link
                                        to="/collections/thiet-bi-truong-hoc"
                                        title="Thiết Bị Trường Học">
                                        Thiết Bị Trường Học
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default MHeader;
