import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

const Discount = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 31 26">
            <g>
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M11.625 15.9792L19.375 9.47916M25.1875 13.5417C25.1875 12.8234 25.5277 12.1345 26.1333 11.6266C26.7389 11.1187 27.5603 10.8333 28.4167 10.8333V9.75C28.4167 5.41666 27.125 4.33333 21.9583 4.33333H9.04168C3.87501 4.33333 2.58334 5.41666 2.58334 9.75V10.2917C3.43977 10.2917 4.26112 10.577 4.86671 11.0849C5.4723 11.5928 5.81251 12.2817 5.81251 13C5.81251 13.7183 5.4723 14.4072 4.86671 14.9151C4.26112 15.423 3.43977 15.7083 2.58334 15.7083V16.25C2.58334 20.5833 3.87501 21.6667 9.04168 21.6667H21.9583C27.125 21.6667 28.4167 20.5833 28.4167 16.25C27.5603 16.25 26.7389 15.9647 26.1333 15.4567C25.5277 14.9488 25.1875 14.26 25.1875 13.5417Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M19.3685 15.9792H19.3802M11.6185 10.0208H11.6289"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </SvgIcon>
    );
};

export default Discount;
