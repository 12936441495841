import React from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

const Menu = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <g>
                <path d="M 3 18 h 18 v -2 H 3 v 2 Z m 0 -5 h 18 v -2 H 3 v 2 Z m 0 -7 v 2 h 18 V 6 H 3 Z"></path>
            </g>
        </SvgIcon>
    );
};

export default Menu;
