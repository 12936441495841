import { QueryDataResponse, TagResponse } from "src/types/service";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useTags = () => {
    return useQuery<QueryDataResponse<TagResponse>>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["tags"],
        queryFn: () => getData({ path: "/tags" }),
    });
};

export default useTags;
