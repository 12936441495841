export const buildQueryString = (obj: object): string => {
    let queryString = "";
    queryString = Object.keys(obj)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(obj[key as keyof typeof obj])}`
        )
        .join("&");
    return queryString ? `?${queryString}` : queryString;
};

export const formatVND = (x: number): string => {
    return x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
};
