import React, { AnchorHTMLAttributes, CSSProperties } from "react";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { Link, styled } from "@mui/material";

import { useRouter } from "src/hooks/useRouter";

// component props interface
export interface NavLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string;
    style?: CSSProperties;
    className?: string;
}
// styled component
const StyledLink = styled(Link)<{ active_route?: string }>(({ theme, active_route }) => ({
    position: "relative",
    color: active_route === "active" ? theme.palette.primary.main : "inherit",
    transition: "color 150ms ease-in-out",
    "&:hover": {
        color: `${theme.palette.primary.main} !important`,
    },
}));

const NavLink: React.FC<NavLinkProps> = ({ href, children, style, className, ...props }) => {
    const { pathname } = useRouter();
    const navigate = useNavigate();

    const checkRouteMatch = () => {
        if (href === "/") return pathname === href;
        return pathname.includes(href);
    };
    // active route
    const currentRoute = checkRouteMatch();

    return (
        <StyledLink
            active_route={currentRoute ? "active" : ""}
            className={clsx(className)}
            onClick={() => navigate(href)}
            style={style}
            {...props}>
            {children}
        </StyledLink>
    );
};

export default NavLink;
