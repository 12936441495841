import { useDispatch, useSelector } from "react-redux";

import {
    clearQueue,
    closeModal,
    OneModal,
    onToggle,
    pushModalQueue,
    shiftModalQueue,
} from "src/store/modalReducer";

const useModal = () => {
    const { isOpenModal, modalQueue, cb } = useSelector((state: any) => state.modal);
    const dispatch = useDispatch<any>();

    const onDismiss = () => {
        dispatch(closeModal(typeof cb === "function" ? cb : null));
        if (modalQueue.length) {
            if (!modalQueue[0]) return;
            const currentModal = modalQueue[0] as OneModal;
            dispatch(shiftModalQueue());
            dispatch(onToggle({ currentModal }));
        }
    };

    const onPush = (currentModal: OneModal) => {
        if (!isOpenModal) {
            console.log("vooooo dyyyyyyyyyy");
            dispatch(onToggle({ currentModal }));
            return;
        }
        pushModalQueue({ currentModal: currentModal });
    };

    const clearQueueModal = () => {
        dispatch(clearQueue());
    };
    return {
        onDismiss,
        onPush,
        clearQueueModal,
    };
};

export default useModal;
