import React, { FC } from "react";

import { useSelector } from "react-redux";

import { Card, Checkbox, Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { H6, Span } from "src/components/Typography";

import { IVendor } from "src/store/vendorReducer";

interface IProductFilterCardProps {
    selectedVendors: string[];
    selectedPrice: {
        from: number | null;
        to: number | null;
    };
    onChangeVendors: AnyToVoidFunction;
    onChangePrice: AnyToVoidFunction;
}

const ProductFilterCard: FC<IProductFilterCardProps> = ({
    selectedVendors,
    selectedPrice,
    onChangePrice,
    onChangeVendors,
}) => {
    const allVendors: IVendor[] = useSelector((state: any) => state.vendor.allVendors);

    return (
        <Card sx={{ p: "18px 27px", overflow: "auto" }} elevation={1}>
            <H6 mb={1.25}>Nhà xuất bản</H6>
            {allVendors.map((vendor, index) => (
                <FormControlLabel
                    control={<Checkbox size="small" color="secondary" />}
                    label={<Span color="inherit">{vendor.name}</Span>}
                    sx={{ display: "flex" }}
                    key={index}
                    value={vendor.id as string}
                    checked={selectedVendors.includes(vendor.id as string)}
                    onChange={onChangeVendors}
                />
            ))}
            <Divider sx={{ my: "1.5rem" }} />
            <H6 mb={2}>Lọc giá</H6>
            <RadioGroup name="radio-buttons-group" value={JSON.stringify(selectedPrice)}>
                {optionsPrice.map((item) => (
                    <FormControlLabel
                        control={<Radio size="small" color="secondary" />}
                        label={<Span color="inherit">{item.title}</Span>}
                        sx={{ display: "flex" }}
                        key={item.id}
                        value={JSON.stringify(item.value)}
                        onChange={onChangePrice}
                    />
                ))}
            </RadioGroup>
        </Card>
    );
};

const optionsPrice = [
    {
        id: 1,
        title: "Dưới 50.000 VND",
        value: {
            from: 0,
            to: 49999,
        },
    },
    {
        id: 2,
        title: "50.000 VND - 100.000 VND",
        value: {
            from: 50000,
            to: 100000,
        },
    },
    {
        id: 3,
        title: "100.000 VND - 200.000 VND",
        value: {
            from: 100000,
            to: 200000,
        },
    },
    {
        id: 4,
        title: "Trên 200.000 VND",
        value: {
            from: 200000,
        },
    },
];
export default ProductFilterCard;
