import { useEffect, useState } from "react";

import { Skeleton } from "@mui/material";

import MyCard from "../MyCard";
import MyImage from "../MyImage";

import useWindowSize from "src/hooks/useWindowSize";

interface IBannerSkeleton {
    src: string;
}
export default function BannerSkeleton({ src }: IBannerSkeleton) {
    const { width } = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setIsLoading(true);
        };
    }, []);
    return !isLoading ? (
        <Skeleton
            variant="rectangular"
            animation="wave"
            width={width}
            height={(width * 401) / 1202}
        />
    ) : (
        <MyImage src={src} width={"100%"} />
    );
}
