import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { apiConfig, processAction } from "src/https";
import { buildQueryString, formatVND } from "src/util";

import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { Box, Card, TextField, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { debounce } from "@mui/material/utils";

import FlexBox from "../FlexBox";
import MyButton from "../MyButton";
import MyImage from "../MyImage";
import { H5, Tiny } from "../Typography";

import { IProduct } from "src/store/productReducer";

// styled components
// also used in the GrocerySearchBox component
export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6,
}));

// also used in the GrocerySearchBox component
export const SearchResultCard = styled(Card)(() => ({
    position: "absolute",
    top: "100%",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    width: "100%",
    zIndex: 99,
}));

export const BoxTextField = styled(Box)(() => ({
    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
        {
            // "-webkit-box-shadow": "0 0 0px 1000px #fff inset",
            transition: "background-color 9999s ease-in-out 0s",
        },
}));

const SearchBox: FC = () => {
    const theme = useTheme();
    const { palette } = theme;
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const [textSearch, setTextSearch] = useState();
    const [resultList, setResultList] = useState<IProduct[]>([]);
    const [total, setTotal] = useState(0);

    const parentRef = useRef();
    const { apiProduct } = apiConfig;

    const search = debounce((e) => {
        const value = e.target?.value;
        setTextSearch(value);
        if (!value) return setResultList([]);
        const query = {
            limit: 4, // for 7 row in desktop
            offset: 0,
            name: value,
        };

        processAction({
            path: apiProduct.getProduct.path + buildQueryString(query),
            method: apiProduct.getProduct.method,
        }).then((res: any) => {
            const { items, paging } = res.data;
            setResultList(items);
            setTotal(paging.total);
        });
    }, 200);

    const goToDetailSearch = () => {
        navigate(`/product/search/${textSearch}`);
    };

    const hanldeSearch = useCallback((event: any) => {
        event.persist();
        search(event);
    }, []);

    const handleDocumentClick = () => {
        setResultList([]);
    };

    useEffect(() => {
        window.addEventListener("click", handleDocumentClick);
        return () => {
            window.removeEventListener("click", handleDocumentClick);
        };
    }, []);
    const handleSubmit = (evt: Event) => {
        evt.preventDefault();
        if (!(textSearch || "").trim().length) return;
        goToDetailSearch();
    };

    return (
        <BoxTextField
            position="relative"
            flex="1 1 0"
            maxWidth="670px"
            mx="auto"
            {...{ ref: parentRef }}>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    placeholder="Tìm kiếm sản phẩm.."
                    fullWidth
                    onChange={hanldeSearch}
                    InputProps={{
                        sx: {
                            height: 44,
                            borderRadius: 300,
                            paddingRight: 0,
                            color: "grey.700",
                            overflow: "hidden",
                            "&:-webkit-autofill": {
                                background: "#fff !important",
                                "-webkit-box-shadow": "0 0 0 30px white inset !important",
                            },
                        },
                        startAdornment: <SearchOutlinedIcon fontSize="small" />,
                    }}
                />
            </form>

            {!!resultList.length && (
                <SearchResultCard elevation={2}>
                    {resultList.map((item, index) => (
                        // <Link to={`/product/search/${item.id}`} key={item.id}>
                        //     <MenuItem key={item.id}>{item.name}</MenuItem>
                        // </Link>
                        <Link key={index} to={`/product/${item.id}`}>
                            <FlexBox
                                alignItems="center"
                                py={2}
                                px={2.5}
                                borderBottom={`1px solid ${palette.divider}`}
                                key={item.id}
                                sx={{
                                    ...(isMobile && {
                                        paddingBlock: "8px !important",
                                        paddingInline: "8px !important",
                                    }),
                                }}>
                                <MyImage
                                    src={item.images && item?.images[0]?.url}
                                    mx={2}
                                    alt={item.name}
                                    height={76}
                                    width={76}
                                    sx={{
                                        objectFit: "contain",
                                        ...(isMobile && {
                                            marginInline: "8px !important",
                                        }),
                                    }}
                                />

                                <Box flex="1 1 0">
                                    <H5
                                        className="title"
                                        fontSize="14px"
                                        sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            "-webkit-line-clamp": "2",
                                            "-webkit-box-orient": "vertical",
                                        }}>
                                        {item.name}
                                    </H5>
                                    <Tiny color="grey.600">
                                        {formatVND((item.variants && item.variants[0].price) || 0)}
                                    </Tiny>
                                </Box>
                            </FlexBox>
                        </Link>
                    ))}
                    {total - 4 > 0 && (
                        <MyButton
                            variant="text"
                            sx={{
                                fontWeight: "bold !important",
                                display: "flex !important",
                                justifyContent: "center !important",
                                width: "100% !important",
                            }}
                            onClick={goToDetailSearch}>
                            <Box>Xem thêm {total - 4} sản phẩm</Box>
                        </MyButton>
                    )}
                </SearchResultCard>
            )}
        </BoxTextField>
    );
};

export default SearchBox;
