import { apiConfig, processAction } from "src/https";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const nameReducer = "menuReducer";
const { apiMenu } = apiConfig;

interface ICollection {
    id: string;
    image_id: string | null;
    image: string | null;
    title: string;
}

export interface IMenuChildren {
    id: string;
    collection_id: string;
    collection: ICollection;
    name: string;
    path: string[];
    sub_menus: IMenuChildren[];
}

interface IStates {
    mainMenu: IMenuChildren[];
    footerMenu: IMenuChildren[];
}

// --- Tạo thunk ---
export const getMenu = createAsyncThunk(`${nameReducer}/getMenu`, async () => {
    const response = await processAction(apiMenu.getAllMenu);
    const menus: IMenuChildren[] = response.data;
    return menus;
});

const menuSlice = createSlice({
    name: nameReducer,
    initialState: {
        mainMenu: [],
        footerMenu: [],
    } as IStates,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMenu.fulfilled, (state, action) => {
            state.mainMenu = action.payload;
        });
    },
});

export default menuSlice.reducer;
