import React, { ReactNode } from "react";

import { Link } from "react-router-dom";

import ArrowRight from "@mui/icons-material/ArrowRight";
import { H2 } from "src/components/Typography";

import FlexBox from "./FlexBox";

export interface CategorySectionHeaderProps {
    title?: string;
    seeMoreLink?: string;
    icon?: ReactNode;
}

const CategorySectionHeader: React.FC<CategorySectionHeaderProps> = ({
    title,
    seeMoreLink,
    icon,
}) => {
    return (
        <FlexBox justifyContent="space-between" alignItems="center" mb={3}>
            <FlexBox alignItems="center">
                {icon && (
                    <FlexBox mr={1} alignItems="center">
                        {icon}
                    </FlexBox>
                )}
                <H2 fontWeight="bold" lineHeight="1">
                    {title}
                </H2>
            </FlexBox>

            {seeMoreLink && (
                <Link to={seeMoreLink}>
                    <FlexBox alignItems="center" ml={1} color="grey.600">
                        Xem tất cả
                        <ArrowRight fontSize="small" color="inherit" />
                    </FlexBox>
                </Link>
            )}
        </FlexBox>
    );
};

export default CategorySectionHeader;
