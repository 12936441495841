import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { modalType } from "src/constants";

import DynamicModal from "src/components/Modal/DynamicModal";

import CheckoutSuccess from "./Layout/CheckoutSuccess";
import SubscriptionSuccess from "./Layout/SubscriptionSuccess";

const Modal = () => {
    const { isOpenModal, currentModalName } = useSelector((state: any) => state.modal);
    const [showDynamicModal, setShowDynamicModal] = useState(false);

    useEffect(() => {
        console.log("isOpenModal", isOpenModal);
        console.log("currentModalName", currentModalName);

        setShowDynamicModal(
            isOpenModal &&
                [modalType.checkoutSuccess, modalType.subscriptionSuccess].includes(
                    currentModalName
                )
        );
    }, [isOpenModal, currentModalName]);
    return (
        <DynamicModal isOpen={showDynamicModal}>
            <>
                {currentModalName === modalType.checkoutSuccess && <CheckoutSuccess />}
                {currentModalName === modalType.subscriptionSuccess && <SubscriptionSuccess />}
            </>
        </DynamicModal>
    );
};

export default Modal;
