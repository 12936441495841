import { useEffect, useState } from "react";

import { apiConfig, processAction } from "src/https";
import { ProductResponse } from "src/types/service";
import { buildQueryString } from "src/util";

import useOnChange from "./useOnChange";

export default function useHandleCollectionDetail(defaultQuery: object) {
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [listProducts, setListProduct] = useState<ProductResponse[]>([]);
    const [isShowClearFilter, setIsShowClearFilter] = useState<boolean>(false);

    const [query, setQuery] = useState<any>({
        limit: 21, // for 7 row in desktop
        offset: 0,
        ...defaultQuery,
    });
    useOnChange(() => {
        setQuery({
            limit: 21, // for 7 row in desktop
            offset: 0,
            ...defaultQuery,
        });
        setIsLoading(true);
        setListProduct([]);
    }, [JSON.stringify(defaultQuery)]);
    const [filterQuery, setFilterQuery] = useState({
        vendor_ids: [],
        from_price: null,
        to_price: null,
    });
    const loadMore = () => {
        setQuery({
            ...query,
            offset: query.offset + query.limit,
        });
        setIsLoadingMore(true);
    };

    useEffect(() => {
        setCanLoadMore(listProducts.length < total);
    }, [listProducts, total]);

    const handleChangePrice = (event: any) => {
        const filterPrice = JSON.parse(event.target.value);
        setFilterQuery({
            ...filterQuery,
            from_price: filterPrice.from,
            to_price: filterPrice.to,
        });
    };

    const handleChangeVendors = (event: any) => {
        const vendorId = event.target.value as never;
        const currentVendorIds: never[] = JSON.parse(JSON.stringify(filterQuery.vendor_ids));
        const indexVendorId = currentVendorIds.findIndex((vendor_id) => vendor_id === vendorId);
        if (indexVendorId === -1) {
            currentVendorIds.push(vendorId);
        } else {
            currentVendorIds.splice(indexVendorId, 1);
        }
        setFilterQuery({
            ...filterQuery,
            vendor_ids: currentVendorIds,
        });
    };

    useOnChange(() => {
        if (filterQuery.vendor_ids.length) {
            setQuery({
                ...query,
                vendor_ids: JSON.stringify(filterQuery.vendor_ids),
                to_price: filterQuery.to_price || 99999999,
                from_price: filterQuery.from_price || 0,
                offset: 0,
            });
        } else {
            setQuery({
                ...query,
                vendor_ids: null,
                to_price: filterQuery.to_price || 99999999,
                from_price: filterQuery.from_price || 0,
                offset: 0,
            });
        }
        setListProduct([]);
    }, [filterQuery.vendor_ids.length, filterQuery.to_price, filterQuery.from_price]);

    useEffect(() => {
        setIsShowClearFilter(
            JSON.stringify(filterQuery) !==
                JSON.stringify({ vendor_ids: [], from_price: null, to_price: null })
        );

        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery]);

    const clearFilter = () => {
        setFilterQuery({
            vendor_ids: [],
            from_price: null,
            to_price: null,
        });
    };

    const { apiProduct } = apiConfig;

    useOnChange(() => {
        processAction({
            path: apiProduct.getProduct.path + buildQueryString(query),
            method: apiProduct.getProduct.method,
        }).then((res: any) => {
            const { items, paging } = res.data;
            setTotal(paging.total);
            const currentListProducts = JSON.parse(JSON.stringify(listProducts));
            currentListProducts.push(...items);
            setListProduct(currentListProducts);
            setIsLoadingMore(false);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(query)]);

    return {
        listProducts,
        total,
        loadMore,
        handleChangePrice,
        handleChangeVendors,
        filterQuery,
        canLoadMore,
        isLoadingMore,
        isLoading,
        isShowClearFilter,
        clearFilter,
    };
}
