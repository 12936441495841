import React from "react";

import { Outlet } from "react-router-dom";

import { Container } from "@mui/material";
import Navbar from "src/components/navbar/Navbar";

import AppLayout from "src/layouts/AppLayout/index";

const NavbarLayout: React.FC = () => {
    return (
        <AppLayout navbar={<Navbar />}>
            <Outlet />
        </AppLayout>
    );
};

export default NavbarLayout;
