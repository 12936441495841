import http from "src/util/https";

import { HttpProps, Response } from "./type";

export type Res = Response & {
    data: {
        error: {};
    };
};

export const endload = (e: any) => {
    let timer = setTimeout(() => {
        e.value = false;
        clearTimeout(timer);
    }, 500);
};

export const processAction = ({ method, path }: HttpProps, values?: {}): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
        try {
            http[method](path, values || {})
                .then((res: Res) => {
                    if (res.data.error) {
                        return reject(res);
                    }
                    resolve(res);
                })
                .catch((err: any) => {
                    return reject(err);
                });
        } catch (error: any) {
            reject(error);
        }
    });
};
