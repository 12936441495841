import React from "react";

import { Box } from "@mui/material";

export interface ProductDescriptionProps {
    description: any;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ description }) => {
    return (
        <Box>
            <div
                dangerouslySetInnerHTML={{
                    __html: description || "",
                }}></div>
        </Box>
    );
};

export default ProductDescription;
