import { FC, JSXElementConstructor, ReactElement, ReactNode } from "react";

import { Modal } from "@mui/material";

interface IDynamicModalProps {
    isOpen: boolean;
    dismissPosition?: "bottom" | "top";
    children: ReactElement<any, string | JSXElementConstructor<any>>;
}
const DynamicModal: FC<IDynamicModalProps> = ({ isOpen, dismissPosition, children }) => {
    return <Modal open={isOpen}>{children}</Modal>;
};

export default DynamicModal;
