import { List } from "@mui/material";

import SideNavItem from "./SidenavItem";

import { MenuItem } from "src/types/menuItem";

export const SideNavSection = ({
    items = [],
    closeNavMobile,
}: {
    items: MenuItem[];
    closeNavMobile: () => void;
}) => {
    return (
        <List
            sx={{ width: "100%", bgcolor: "background.paper", paddingX: 2 }}
            component="nav"
            aria-labelledby="nested-list-subheader">
            {items.map((item) => (
                <SideNavItem item={item} key={item.id} level={0} closeNavMobile={closeNavMobile} />
            ))}
        </List>
    );
};
