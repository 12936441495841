import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatVND } from "src/util";

import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlexBox from "src/components/FlexBox";
import ShoppingBagOutlined from "src/components/icons/ShoppingBagOutlined";
import MyAvatar from "src/components/MyAvatar";
import MyButton from "src/components/MyButton";
import MyIconButton from "src/components/MyIconButton";
import MyImage from "src/components/MyImage";
import { H5, Tiny } from "src/components/Typography";

import shoppingBag from "src/assets/images/logos/shopping-bag.svg";
import noImage from "src/assets/images/no_image.jpg";
import { CartItem, changeCartAmount } from "src/store/cartReducer";

interface IMiniCartProps {
    toggleSidenav?: () => void;
}

const MiniCart: React.FC<IMiniCartProps> = ({ toggleSidenav }) => {
    const { palette } = useTheme();
    let cartList = useSelector((state: any) => state.cart.cartList);
    const dispatch = useDispatch<any>();

    const handleCartAmountChange = useCallback(
        (amount: number, product: CartItem) => () => {
            dispatch(
                changeCartAmount({
                    ...product,
                    qty: amount,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const getTotalPrice = () => {
        return (
            cartList.reduce(
                (accumulator: number, item: CartItem) => accumulator + item.price * item.qty,
                0
            ) || 0
        );
    };

    return (
        <Box width="380px">
            <Box overflow="auto" height={`calc(100vh - ${cartList.length ? "80px" : "0px"})`}>
                <FlexBox
                    alignItems="center"
                    justifyContent="space-between"
                    m="0px 20px"
                    height="74px"
                    color="secondary.main">
                    <FlexBox alignItems="center">
                        <ShoppingBagOutlined color="inherit" />
                        <Box fontWeight={600} fontSize="16px" ml={1}>
                            {cartList.length} sản phẩm
                        </Box>
                    </FlexBox>

                    <MyIconButton size="large" onClick={toggleSidenav}>
                        <Close
                            fontSize="large"
                            sx={{
                                fontSize: 25,
                            }}
                        />
                    </MyIconButton>
                </FlexBox>

                <Divider />

                {!cartList.length && (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100% - 74px)">
                        <MyImage src={shoppingBag} width={90} height={100} />
                        <Box
                            component="p"
                            mt={2}
                            color="grey.600"
                            textAlign="center"
                            maxWidth="200px">
                            Hiện chưa có sản phẩm
                        </Box>
                    </FlexBox>
                )}
                {cartList.map((item: CartItem) => (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        py={2}
                        px={2.5}
                        borderBottom={`1px solid ${palette.divider}`}
                        key={item.id}>
                        <FlexBox justifyContent="space-between" width="100%">
                            <Link to={`/product/${item.productId}`}>
                                <MyAvatar
                                    src={item.imgUrl || noImage}
                                    mx={2}
                                    alt={item.name}
                                    height={76}
                                    width={76}
                                />
                            </Link>

                            <Box flex="1 1 0">
                                <Link to={`/product/${item.productId}`}>
                                    <H5 className="title" fontSize="14px">
                                        {item.productName} - {item.name}
                                    </H5>
                                </Link>
                                <Tiny color="grey.600">
                                    {formatVND(item.price)} x {item.qty}
                                </Tiny>
                            </Box>
                            <MyIconButton
                                ml={2.5}
                                size="small"
                                onClick={handleCartAmountChange(0, item)}>
                                <Close fontSize="small" />
                            </MyIconButton>
                        </FlexBox>
                        <FlexBox
                            justifyContent="space-between"
                            sx={{
                                width: "100%",
                            }}>
                            <FlexBox alignItems="center">
                                <MyButton
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        height: "32px",
                                        width: "32px",
                                        borderRadius: "300px",
                                    }}
                                    onClick={handleCartAmountChange(item.qty - 1, item)}
                                    disabled={item.qty === 1}>
                                    <Remove fontSize="small" />
                                </MyButton>
                                <Box fontWeight={600} fontSize="15px" mx={1.5}>
                                    {item.qty}
                                </Box>
                                <MyButton
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                        height: "32px",
                                        width: "32px",
                                        borderRadius: "300px",
                                    }}
                                    onClick={handleCartAmountChange(item.qty + 1, item)}>
                                    <Add fontSize="small" />
                                </MyButton>
                            </FlexBox>
                            <Box fontWeight={600} fontSize="14px" color="primary.main" mt={0.5}>
                                {formatVND(item.qty * item.price)}
                            </Box>
                        </FlexBox>
                    </FlexBox>
                ))}
            </Box>

            {!!cartList.length && (
                <Box p={2.5} paddingBottom={0}>
                    <Link to="/cart">
                        <MyButton
                            variant="contained"
                            color="primary"
                            sx={{
                                height: "40px",
                            }}
                            onClick={toggleSidenav}
                            fullWidth>
                            Thanh toán ngay ({formatVND(getTotalPrice())})
                        </MyButton>
                    </Link>
                </Box>
            )}
        </Box>
    );
};

MiniCart.defaultProps = {
    toggleSidenav: () => {},
};

export default MiniCart;
