import { MenuDataResponse } from "src/types/service";

import { MenuItem } from "src/types/menuItem";

export const transformData = (menuItems: MenuDataResponse["menu_items"]): MenuItem => {
    const itemMap = new Map();

    for (const item of menuItems) {
        const newItem = { ...item, sub_menus: [] };
        itemMap.set(item.collection_id, newItem);

        if (item.path.length > 1) {
            const parentId = item.path[item.path.length - 2];
            const parent = itemMap.get(parentId);
            if (parent) {
                parent.sub_menus.push(newItem);
            }
        }
    }

    const rootItem = itemMap.get("left-menu");

    return rootItem;
};
