export function getPlatform() {
    const { userAgent, platform } = window.navigator;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os: "macOS" | "iOS" | "Windows" | "Android" | "Linux" | undefined;

    if (
        iosPlatforms.indexOf(platform) !== -1 ||
        // For new IPads with M1 chip and IPadOS platform returns "MacIntel"
        (platform === "MacIntel" && "maxTouchPoints" in navigator && navigator.maxTouchPoints > 2)
    ) {
        os = "iOS";
    } else if (macosPlatforms.indexOf(platform) !== -1) {
        os = "macOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (/Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
}

export const PLATFORM_ENV = getPlatform();
export const IS_MAC_OS = PLATFORM_ENV === "macOS";
export const IS_IOS = PLATFORM_ENV === "iOS";
export const IS_ANDROID = PLATFORM_ENV === "Android";
export const IS_SAFARI = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const IS_YA_BROWSER = navigator.userAgent.includes("YaBrowser");

export const IS_PWA =
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator as any).standalone ||
    document.referrer.includes("android-app://");

export const IS_TOUCH_ENV = window.matchMedia("(pointer: coarse)").matches;
