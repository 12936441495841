import { VariantResponse } from "src/types/service";
import { mappingValueAttributeVariant } from "src/util/common";

import { Button } from "@mui/material";

const ProductVariantButton = ({
    variant,
    isSelected,
    onClick,
    size = "normal",
}: {
    onClick: () => void;
    variant: VariantResponse;
    isSelected: boolean;
    size?: "small" | "normal";
}) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                borderRadius: "4px",
                border: "1px solid #afafaf",
                ...(isSelected
                    ? {
                          background: "#80c040",
                          color: "white",
                          ":hover": { background: "#80c040", color: "white" },
                      }
                    : {}),
                ...(size === "small" ? { fontSize: "10px" } : {}),
            }}>
            {variant.attributes.map(mappingValueAttributeVariant).join(" / ")}
        </Button>
    );
};

export default ProductVariantButton;
