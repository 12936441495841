import { SpecialTagResponse } from "src/types/service";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useSpecialTag = () => {
    return useQuery<SpecialTagResponse>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["tag-special"],
        queryFn: () => getData({ path: "/tags/special" }),
    });
};

export default useSpecialTag;
