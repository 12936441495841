import React, { useEffect } from "react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";

interface MuiThemeProps {
    children: React.ReactNode;
}
const MuiTheme: React.FC<MuiThemeProps> = ({ children }) => {
    // console.log(theme);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default MuiTheme;
