import axios, { AxiosStatic } from "axios";
import { PathGet } from "src/types/service";

export const getData = ({
    path,
    config = undefined,
    slug = "",
}: {
    path: PathGet;
    config?: Parameters<AxiosStatic["get"]>[1];
    slug?: string;
}) => {
    const pathname = slug ? `${path}/${slug}` : `${path}`;
    return axios
        .get(`https://${process.env.REACT_APP_API_END_POINT}${pathname}`, config)
        .then((res) => res.data);
};

export const postData = <D>(
    path: "/orders",
    data: D,
    config: Parameters<AxiosStatic["post"]>[2] = undefined
) => {
    return axios.post(`https://${process.env.REACT_APP_API_END_POINT}${path}`, data, {
        ...config,
    });
};
