import { CheckoutFormValuesProps } from "src/constants/type";
import { postData } from "src/util/service";

import { useMutation } from "@tanstack/react-query";

const useCreateOrder = ({ onSuccess }: { onSuccess: () => void }) => {
    return useMutation({
        mutationKey: ["create-order"],
        mutationFn: (data: CheckoutFormValuesProps) =>
            postData<CheckoutFormValuesProps>("/orders", data),
        onSuccess() {
            onSuccess();
        },
    });
};

export default useCreateOrder;
