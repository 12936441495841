import { FC, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Badge, Box, Container, Drawer, IconButton, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlexBox from "src/components/FlexBox";
import Menu from "src/components/icons/Menu";
import ShoppingBagOutlined from "src/components/icons/ShoppingBagOutlined";
import MyImage from "src/components/MyImage";
import NavbarMobile from "src/components/navbar/NavbarMobile";
import SearchBox from "src/components/search-box/SearchBox";
import Sidenav from "src/components/sidenav/Sidenav";

import MiniCart from "../mini-cart/MiniCart";

import imgLogo from "src/assets/images/logo.webp";
import { CartItem, updateIsOpenMiniCart } from "src/store/cartReducer";

// component props interface
interface HeaderProps {
    className?: string;
    isFixed?: boolean;
}

// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    zIndex: 1,
    paddingBlock: "1rem",
    background: theme.palette.background.paper,
    transition: "height 250ms ease-in-out",
}));

const Header: FC<HeaderProps> = ({ isFixed, className }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    let cartList = useSelector((state: any) => state.cart.cartList);
    let isOpenMiniCart = useSelector((state: any) => state.cart.isOpenMiniCart);
    const getTotalItems = () => {
        return (
            cartList.reduce((accumulator: number, item: CartItem) => accumulator + item.qty, 0) || 0
        );
    };
    const [showNavMobile, setShowNavMobile] = useState<boolean>();

    const handleShowNavMobile = () => {
        setShowNavMobile(!showNavMobile);
    };

    const dispatch = useDispatch<any>();
    const toggleSidenav = () => {
        dispatch(updateIsOpenMiniCart(!isOpenMiniCart));
    };

    const cartHandle = (
        <Badge
            badgeContent={getTotalItems() || "0"}
            color="primary"
            sx={{
                ".MuiBadge-colorPrimary": {
                    color: "#fff !important",
                },
            }}>
            <Box
                onClick={toggleSidenav}
                component={IconButton}
                ml={2.5}
                bgcolor="grey.200"
                p={1.25}
                sx={{
                    ...(isMobile && {
                        padding: "8px",
                    }),
                }}>
                <ShoppingBagOutlined
                    sx={{
                        ...(isMobile && {
                            fontSize: "1rem",
                        }),
                    }}
                />
            </Box>
        </Badge>
    );

    return (
        <HeaderWrapper className={clsx(className)}>
            <Container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                    position: "relative",
                }}>
                <Sidenav
                    open={showNavMobile}
                    width={320}
                    position="left"
                    toggleSidenav={handleShowNavMobile}
                    handle={
                        <IconButton sx={{ display: { xs: "block", md: "none" } }}>
                            <Menu color="secondary" fontSize="small" />
                        </IconButton>
                    }>
                    <NavbarMobile closeNavMobile={handleShowNavMobile} />
                </Sidenav>

                <FlexBox
                    alignItems="center"
                    sx={{
                        ...(isMobile && {
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                        }),
                    }}>
                    <Link to="/">
                        <MyImage
                            sx={{ height: { xs: 40, md: 60 } }}
                            mb={0.5}
                            src={imgLogo}
                            alt="logo"
                        />
                    </Link>
                </FlexBox>

                <FlexBox
                    justifyContent="center"
                    flex="1 1 0"
                    sx={{ display: { xs: "none", md: "block" } }}>
                    <SearchBox />
                </FlexBox>

                <FlexBox alignItems="center">{cartHandle}</FlexBox>
            </Container>
            <Container
                sx={{
                    display: { xs: "block", md: "none" },
                }}>
                <FlexBox justifyContent="center" flex="1 1 0">
                    <SearchBox />
                </FlexBox>
            </Container>
            <Drawer open={isOpenMiniCart} anchor="right" onClose={toggleSidenav}>
                <MiniCart toggleSidenav={toggleSidenav} />
            </Drawer>
        </HeaderWrapper>
    );
};

export default Header;
