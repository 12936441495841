import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "src/store/store";

import "./index.css";
import App from "src/App";
import MuiTheme from "src/theme/MuiTheme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <MuiTheme>
            <App />
        </MuiTheme>
    </Provider>
);
