import { Card, CardProps, Skeleton, Stack, styled } from "@mui/material";

import MyCard from "../MyCard";

const StyledBazarCard = styled(MyCard)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    margin: "auto",
    overflow: "hidden",
    transition: "all 250ms ease-in-out",
    borderRadius: "8px",
}));
export default function ProductCardSkeleton({ ...other }: CardProps) {
    return (
        <StyledBazarCard {...other}>
            <Stack spacing={2} sx={{ p: 3 }}>
                <Skeleton variant="rectangular" animation="wave" width="100%" height={150} />

                {[...Array(3)].map((_, index) => (
                    <Skeleton
                        key={index}
                        variant="text"
                        sx={{
                            height: 20 - index * 2,
                            width: `calc(80% - calc(20 * ${index}%))`,
                        }}
                    />
                ))}
            </Stack>
        </StyledBazarCard>
    );
}
