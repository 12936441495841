import { transformData } from "src/util/menu";

import { SideNavSection } from "./SidenavSection";

import useMenus from "src/hooks/useMenus";

const NavbarMobile = ({ closeNavMobile }: { closeNavMobile: () => void }) => {
    const { data: menuData } = useMenus();

    if (!menuData) return <></>;

    return (
        <SideNavSection
            items={transformData(menuData.menu_items).sub_menus}
            closeNavMobile={closeNavMobile}></SideNavSection>
    );
};

export default NavbarMobile;
