import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const InformationCompany = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Liên Hệ</Typography>

                            <Typography variant="body1">
                                Để xây dựng Thương hiệu mạnh, một trong những định hướng quan trọng
                                hàng đầu của Nhà Sách Kiên Giang là chiến lược phát triển nguồn nhân
                                lực - mấu chốt của mọi sự thành công.
                            </Typography>

                            <Typography variant="body1">
                                Nhà Sách Kiên Giang có hơn 2.200 CB-CNV, trình độ chuyên môn giỏi,
                                nhiệt tình, năng động, chuyên nghiệp. Lực lượng quản lý Nhà Sách
                                Kiên Giang có thâm niên công tác, giỏi nghiệp vụ nhiều kinh nghiệm,
                                có khả năng quản lý tốt và điều hành đơn vị hoạt động hiệu quả.
                            </Typography>

                            <Typography variant="body1">
                                Kết hợp tuyển dụng nguồn nhân lực đầu vào có chất lượng và kế hoạch
                                bồi dưỡng kiến thức, rèn luyện bổ sung các kỹ năng và chuẩn bị đội
                                ngũ kế thừa theo hướng chính qui thông qua các lớp học ngắn hạn, dài
                                hạn; các lớp bồi dưỡng CB-CNV được tổ chức trong nước cũng như ở
                                nước ngoài đều được lãnh đạo Nhà Sách Kiên Giang đặc biệt quan tâm
                                và tạo điều kiện triển khai thực hiện. Chính vì thế, trình độ chuyên
                                môn của đội ngũ CB-CNV ngày càng được nâng cao, đáp ứng nhu cầu ngày
                                càng tăng của công việc cũng như sự phát triển của xã hội đang trên
                                đường hội nhập.
                            </Typography>
                            <Typography variant="body1">
                                Nhà sách Kiên Giang chuyên kinh doanh sách tài chính, sách chứng
                                khoán, sách kinh doanh, sách marketing, sách bất đống sản. với sứ
                                mệnh Kiên Giang là thương hiệu hàng đầu nhà sách online tại việt nam
                                chúng tôi hi vọng sẽ mang đến mọi tri thức văn hóa đọc đến mọi nhà
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default InformationCompany;
