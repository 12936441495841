import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatVND } from "src/util";

import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Remove from "@mui/icons-material/Remove";
import { Button, Card, IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "src/components/FlexBox";
import MyImage from "src/components/MyImage";
import { Span } from "src/components/Typography";

import noImage from "src/assets/images/no_image.jpg";
import { CartItem, changeCartAmount } from "src/store/cartReducer";
import { IProduct } from "src/store/productReducer";

export interface ProductCard2Props {
    product: CartItem;
}

const ProductCard2Style = styled(Card)(({ theme }) => ({
    position: "relative",
    display: "flex",
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "1.5rem",
    boxShadow: theme.shadows[2],

    "& .product-details": {
        padding: "20px",
    },

    "& .title": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },

    "@media only screen and (max-width: 425px)": {
        flexWrap: "wrap",
        img: {
            height: "auto",
            minWidth: "100%",
        },
    },
}));

const ProductCard2: React.FC<ProductCard2Props> = ({ product }) => {
    const [cartItem, setCartItem] = useState<CartItem | undefined>(undefined);
    let cartList = useSelector((state: any) => state.cart.cartList);

    useEffect(() => {
        setCartItem(cartList.find((item: CartItem) => item.id === product.id));
    }, [cartList, product.id]);
    const dispatch = useDispatch<any>();

    const handleCartAmountChange = useCallback(
        (amount: number) => () => {
            dispatch(
                changeCartAmount({
                    ...product,
                    qty: amount,
                })
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const navigate = useNavigate();

    const goToDetail = () => {
        navigate(`/product/${product.productId}`);
    };
    return (
        <ProductCard2Style>
            <MyImage
                src={product?.imgUrl || noImage}
                height={140}
                width={140}
                style={{
                    padding: "5px",
                }}
                alt={product.name}
            />
            <FlexBox
                className="product-details"
                flexDirection="column"
                justifyContent="space-between"
                minWidth="0px"
                width="100%">
                <Box sx={{ width: "calc(100% - 30px)", cursor: "pointer" }} onClick={goToDetail}>
                    <Span
                        sx={{
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                        }}
                        className="title"
                        fontWeight="600"
                        fontSize="18px"
                        mb={1}>
                        {product.productName} - {product.name}
                    </Span>
                </Box>
                <Box position="absolute" right="1rem" top="1rem">
                    <IconButton
                        size="small"
                        sx={{
                            padding: "4px",
                            ml: "12px",
                        }}
                        onClick={handleCartAmountChange(0)}>
                        <Close fontSize="small" />
                    </IconButton>
                </Box>

                <FlexBox
                    // width="100%"
                    justifyContent="space-between"
                    alignItems="flex-end">
                    <FlexBox flexWrap="wrap" alignItems="center">
                        <Span color="grey.600" mr={1}>
                            {formatVND(product?.price || 0)} x {cartItem?.qty}
                        </Span>
                        <Span fontWeight={600} color="primary.main" mr={2}>
                            {formatVND((product?.price || 0) * (cartItem?.qty || 0))}
                        </Span>
                    </FlexBox>

                    <FlexBox alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            // padding="5px"
                            // size="none"
                            // borderColor="primary.light"
                            disabled={cartItem?.qty === 1}
                            sx={{ p: "5px" }}
                            onClick={handleCartAmountChange((cartItem?.qty || 0) - 1)}>
                            <Remove fontSize="small" />
                        </Button>
                        <Span mx={1} fontWeight="600" fontSize="15px">
                            {cartItem?.qty}
                        </Span>
                        <Button
                            variant="outlined"
                            color="primary"
                            // padding="5px"
                            // size="none"
                            // borderColor="primary.light"
                            sx={{ p: "5px" }}
                            onClick={handleCartAmountChange((cartItem?.qty || 0) + 1)}>
                            <Add fontSize="small" />
                        </Button>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </ProductCard2Style>
    );
};

export default ProductCard2;
