import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const nameReducer = "modalReducer";

export interface OneModal {
    name: string;
    variable?: Record<any, any>;
    cb?: NoneToVoidFunction;
}

interface currentModal {
    currentModal: OneModal;
}
interface IStates {
    isOpenModal: boolean;
    modalQueue: OneModal[];
    currentModalName: string;
    variable: Record<any, any>;
    cb?: NoneToVoidFunction;
}

// --- Tạo thunk ---
const modalSlice = createSlice({
    name: nameReducer,
    initialState: {
        isOpenModal: false,
        modalQueue: [],
        currentModalName: "",
        variable: {},
    } as IStates,
    reducers: {
        clearQueue(state) {
            state.modalQueue = [];
        },
        pushModalQueue(state, action: PayloadAction<currentModal>) {
            state.modalQueue = [...state.modalQueue, action.payload.currentModal];
        },
        shiftModalQueue(state) {
            state.modalQueue.shift();
        },

        onToggle(state, action: PayloadAction<currentModal>) {
            const currentModal = action.payload.currentModal;
            console.log("onToggle in storeeeeeeee", closeModal);
            state.isOpenModal = true;
            state.currentModalName = currentModal.name;
            state.variable = currentModal.variable as Record<any, any>;
            state.cb = currentModal.cb;
        },

        closeModal(state, action: PayloadAction<NoneToVoidFunction | null>) {
            state.isOpenModal = false;
            if (action.payload) {
                action.payload();
            }
            state.currentModalName = "";
        },
    },
});

export const { clearQueue, pushModalQueue, shiftModalQueue, onToggle, closeModal } =
    modalSlice.actions;

export default modalSlice.reducer;
