import { apiConfig, processAction } from "src/https";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const nameReducer = "vendorReducer";
const { apiVendor } = apiConfig;

export interface IVendor {
    id: string | null;
    name: string;
}

interface IStates {
    allVendors: IVendor[];
}

// --- Tạo thunk ---
export const getAllVendors = createAsyncThunk(`${nameReducer}/getAllVendors`, async () => {
    const response = await processAction(apiVendor.getAllVendor);
    const allVendors: IVendor[] = response.data.items;
    return allVendors;
});

const vendorSlice = createSlice({
    name: nameReducer,
    initialState: {
        allVendors: [],
    } as IStates,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllVendors.fulfilled, (state, action) => {
            state.allVendors = action.payload;
        });
    },
});

export default vendorSlice.reducer;
