import { MenuDataResponse } from "src/types/service";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

const useMenus = () => {
    return useQuery<MenuDataResponse>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: ["menus"],
        queryFn: () => getData({ path: "/menus" }),
    });
};

export default useMenus;
