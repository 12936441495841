import React, { FC, Fragment, useCallback, useState } from "react";

import Footer from "src/components/footer/Footer";
import Header from "src/components/header/Header";
import Sticky from "src/components/sticky/Sticky";

type AppLayoutProps = {
    navbar?: React.ReactChild;
    children?: React.ReactNode;
};

const AppLayout: FC<AppLayoutProps> = ({ children, navbar }) => {
    const [isFixed, setIsFixed] = useState(false);

    const toggleIsFixed = useCallback((fixed: boolean) => {
        setIsFixed(fixed);
    }, []);

    return (
        <Fragment>
            <Sticky fixedOn={0} onSticky={toggleIsFixed}>
                <Header isFixed={isFixed} />
            </Sticky>

            {navbar && <div className="section-after-sticky">{navbar}</div>}
            {!navbar ? <div className="section-after-sticky">{children}</div> : children}

            <Footer />
        </Fragment>
    );
};

export default AppLayout;
