import { Control, Controller, FieldPath } from "react-hook-form";
// @types
import { CheckoutFormValuesProps } from "src/constants/type";

// @mui
import { Stack, TextField } from "@mui/material";

// ----------------------------------------------------------------------

type Props = {
    control: Control<CheckoutFormValuesProps>;
};
export default function TravelCheckOutShippingForm({ control }: Props) {
    return (
        <Stack spacing={5}>
            <section>
                <Stack spacing={2.5}>
                    <Stack spacing={{ xs: 2.5, md: 2 }} direction={{ xs: "column", md: "row" }}>
                        <Field control={control} name="last_name" label="Họ" />
                        <Field control={control} name="first_name" label="Tên" />
                    </Stack>
                    <Field control={control} name="phone_number" label="Số điện thoại" />
                    <Field control={control} name="address" label="Địa chỉ" />
                </Stack>
            </section>
        </Stack>
    );
}

// ----------------------------------------------------------------------

type FieldProps = {
    control: Control<CheckoutFormValuesProps>;
    name: FieldPath<CheckoutFormValuesProps>;
    label: string;
};

function Field({ control, name, label, ...other }: FieldProps) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    label={label}
                    error={Boolean(error)}
                    helperText={error?.message}
                    type="filled"
                />
            )}
            {...other}
        />
    );
}
