import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import FilterList from "@mui/icons-material/FilterList";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Container, Grid, IconButton } from "@mui/material";
import CollectionIntroCard from "src/components/collection/CollectionIntroCard";
import FlexBox from "src/components/FlexBox";
import ProductCardGridList from "src/components/products/ProductCardGridList";
import ProductFilterCard from "src/components/products/ProductFilterCard";
import Sidenav from "src/components/sidenav/Sidenav";
import ProductCardSkeleton from "src/components/skeleton/ProductCardSkeleton";
import { H2, H4 } from "src/components/Typography";

import useHandleQueryProduct from "src/hooks/useHandleQueryProduct";
import useWindowSize from "src/hooks/useWindowSize";
import { getCollectionById, ICollection } from "src/store/collectionReducer";

const Collection = () => {
    const { width } = useWindowSize();
    const isTablet = width < 1025;
    let { id } = useParams();
    const {
        listProducts,
        filterQuery,
        total,
        canLoadMore,
        isLoading,
        isLoadingMore,
        isShowClearFilter,
        loadMore,
        handleChangePrice,
        handleChangeVendors,
        clearFilter,
    } = useHandleQueryProduct(
        id !== "all"
            ? {
                  collection_ids: JSON.stringify([id]),
              }
            : {}
    );

    let collectionById = useSelector((state: any) => state.collection.collectionById);

    const [currentCollection, setCurrentCollection] = useState<ICollection | null>();
    const dispatch = useDispatch<any>();
    useEffect(() => {
        if (collectionById[id as keyof typeof collectionById]) {
            let currentCollection = collectionById[id as keyof typeof collectionById];
            setCurrentCollection(currentCollection);
            return;
        }
        dispatch(getCollectionById(id as string));
        setCurrentCollection(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        let currentCollection = collectionById[id as keyof typeof collectionById];
        setCurrentCollection(currentCollection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionById]);

    return (
        <Container sx={{ my: "2rem", pb: "2rem" }}>
            <CollectionIntroCard />
            <Grid container spacing={3}>
                <Grid
                    item
                    md={3}
                    xs={12}
                    sx={{
                        "@media only screen and (max-width: 1024px)": {
                            display: "none",
                        },
                    }}>
                    <ProductFilterCard
                        selectedVendors={filterQuery.vendor_ids}
                        selectedPrice={{
                            from: filterQuery.from_price,
                            to: filterQuery.to_price,
                        }}
                        onChangeVendors={handleChangeVendors}
                        onChangePrice={handleChangePrice}
                    />
                    {isShowClearFilter && (
                        <Box
                            component={LoadingButton}
                            variant="outlined"
                            loading={isLoadingMore}
                            sx={{
                                minWidth: "160px",
                                maxWidth: "80%",
                                position: "relative",
                                transform: "translateX(-50%)",
                                left: "50%",
                            }}
                            color={(theme) => theme.palette.primary.main}
                            size="small"
                            marginTop={2}
                            height={54}
                            onClick={clearFilter}>
                            Xoá bộ lọc
                        </Box>
                    )}
                </Grid>

                <Grid item md={9} xs={12}>
                    <FlexBox
                        sx={{
                            mb: "1rem",
                        }}
                        justifyContent="space-between"
                        alignItems={isTablet ? "flex-end" : "center"}>
                        <FlexBox flexDirection="column">
                            <H2>{currentCollection?.title}</H2>
                            {isTablet && (
                                <H4>
                                    <b>{total || 0}</b> sản phẩm
                                </H4>
                            )}
                        </FlexBox>
                        {!isTablet && (
                            <H4>
                                <b>{total || 0}</b> sản phẩm
                            </H4>
                        )}
                        {isTablet && (
                            <Sidenav
                                position="left"
                                handle={
                                    <IconButton
                                        sx={{
                                            marginLeft: "auto",
                                            display: "block",
                                        }}>
                                        <FilterList fontSize="small" />
                                    </IconButton>
                                }>
                                <ProductFilterCard
                                    selectedVendors={filterQuery.vendor_ids}
                                    selectedPrice={{
                                        from: filterQuery.from_price,
                                        to: filterQuery.to_price,
                                    }}
                                    onChangeVendors={handleChangeVendors}
                                    onChangePrice={handleChangePrice}
                                />
                                {isShowClearFilter && (
                                    <Box
                                        component={LoadingButton}
                                        variant="outlined"
                                        loading={isLoadingMore}
                                        sx={{
                                            minWidth: "160px",
                                            maxWidth: "80%",
                                            position: "relative",
                                            transform: "translateX(-50%)",
                                            left: "50%",
                                        }}
                                        color={(theme) => theme.palette.primary.main}
                                        size="small"
                                        marginTop={2}
                                        height={54}
                                        onClick={clearFilter}>
                                        Xoá bộ lọc
                                    </Box>
                                )}
                            </Sidenav>
                        )}
                    </FlexBox>
                    {isLoading ? (
                        <Grid container spacing={3}>
                            {[...Array(12)].map((_, index) => (
                                <Grid item lg={4} sm={6} xs={12} key={index}>
                                    <ProductCardSkeleton key={index} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : listProducts.length ? (
                        <ProductCardGridList listProducts={listProducts} />
                    ) : (
                        <H4 textAlign={"center"}>Hiện chưa có sản phẩm ở trang này!</H4>
                    )}
                    {canLoadMore && (
                        <Box
                            component={LoadingButton}
                            variant="outlined"
                            loading={isLoadingMore}
                            sx={{
                                position: "relative",
                                transform: "translateX(-50%)",
                                left: "50%",
                            }}
                            color={(theme) => theme.palette.primary.main}
                            size="small"
                            marginTop={2}
                            height={54}
                            onClick={loadMore}>
                            Xem thêm sản phẩm
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default Collection;
