import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Box, Container, Paper, SvgIcon, Typography } from "@mui/material";

const SubscriptionSuccess = () => {
    return (
        <Box
            sx={{
                p: 3,
            }}>
            <Container maxWidth="sm">
                <Paper
                    elevation={12}
                    sx={{
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Avatar
                        sx={{
                            backgroundColor: "success.lightest",
                            color: "success.main",
                            mb: 2,
                        }}>
                        <SvgIcon>
                            <CheckIcon />
                        </SvgIcon>
                    </Avatar>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                        }}>
                        Đăng ký nhận bản tin thành công
                    </Typography>
                </Paper>
            </Container>
        </Box>
    );
};

export default SubscriptionSuccess;
