import { useEffect, useMemo, useState } from "react";

import { throttle } from "src/util/schedulers";

import useDebouncedCallback from "src/hooks/useDebouncedCallback";
import windowSize from "src/util/windowSize";

const THROTTLE = 250;
interface ApiDimensions {
    width: number;
    height: number;
}

const useWindowSize = () => {
    const [size, setSize] = useState<ApiDimensions>(windowSize.get());
    const [isResizing, setIsResizing] = useState(false);
    const setIsResizingDebounced = useDebouncedCallback(setIsResizing, [], THROTTLE, true);

    const result = useMemo(() => ({ ...size, isResizing }), [isResizing, size]);

    useEffect(() => {
        const throttledSetIsResizing = throttle(
            () => {
                setIsResizing(true);
            },
            THROTTLE,
            true
        );

        const throttledSetSize = throttle(
            () => {
                setSize(windowSize.get());
                setIsResizingDebounced(false);
            },
            THROTTLE,
            false
        );

        const handleResize = () => {
            throttledSetIsResizing();
            throttledSetSize();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setIsResizingDebounced]);

    return result;
};

export default useWindowSize;
