/* eslint-disable react/no-unescaped-entities */
import React from "react";

import { Control, Controller, FieldPath, useForm } from "react-hook-form";
import { emailRegExp, modalType } from "src/constants";
import * as Yup from "yup";

import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { yupResolver } from "@hookform/resolvers/yup";
import useModal from "src/hooks/useModal";

export type SubscriptionFormValuesProps = {
    email: string;
};

const FormSchema = Yup.object().shape({
    email: Yup.string().required("Vui lòng nhập email").matches(emailRegExp, "Email không hợp lệ"),
});
const Subscription = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true,
    });
    const { onPush, onDismiss } = useModal();

    const onSubmit = async (data: SubscriptionFormValuesProps) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        onPush({
            name: modalType.subscriptionSuccess,
        });
        setTimeout(() => {
            onDismiss();
        }, 3000);
        reset();
    };
    const {
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm<SubscriptionFormValuesProps>({
        mode: "onTouched",
        resolver: yupResolver(FormSchema),
        defaultValues: {
            email: "",
        },
    });

    return (
        <Box
            sx={{
                position: "relative",
                transform: "translateY(-10%)",
            }}>
            <Box component={Card} boxShadow={4} paddingY={3}>
                <CardContent>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <Box marginBottom={4}>
                            <Typography
                                variant="h4"
                                align={"center"}
                                data-aos={"fade-up"}
                                gutterBottom
                                sx={{ fontWeight: 700 }}>
                                Đăng ký nhận bản tin
                            </Typography>
                            <Typography
                                variant="h6"
                                align={"center"}
                                color={"text.secondary"}
                                data-aos={"fade-up"}>
                                Để cập nhật những sản phẩm mới, nhận thông tin ưu đãi đặc biệt và
                                thông tin giảm giá khác.
                            </Typography>
                        </Box>
                        <form
                            style={{
                                width: "100%",
                            }}
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box
                                width={1}
                                display="flex"
                                flexDirection={{ xs: "column", sm: "row" }}
                                alignItems={{ xs: "stretched", sm: "flex-start" }}
                                justifyContent={"center"}>
                                <Field control={control} name="email" label="Email" />

                                <Box
                                    component={Button}
                                    type="submit"
                                    variant="outlined"
                                    color={(theme) => theme.palette.primary.main}
                                    size="large"
                                    fullWidth={isMd ? false : true}
                                    marginTop={{ xs: 2, sm: 0 }}
                                    marginLeft={{ sm: 2 }}
                                    height={54}
                                    endIcon={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            width={24}
                                            height={24}>
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                            />
                                        </svg>
                                    }>
                                    ĐĂNG KÝ
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </CardContent>
            </Box>
        </Box>
    );
};

type FieldProps = {
    control: Control<SubscriptionFormValuesProps>;
    name: FieldPath<SubscriptionFormValuesProps>;
    label: string;
};

function Field({ control, name, label, ...other }: FieldProps) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    fullWidth
                    label={label}
                    error={Boolean(error)}
                    helperText={error?.message}
                    type="outlined"
                    sx={{ maxWidth: { xs: 1, sm: 400 }, width: 1 }}
                />
            )}
            {...other}
        />
    );
}

export default Subscription;
