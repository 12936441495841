import cartReducer from "./cartReducer";
import collectionReducer from "./collectionReducer";
import menuReducer from "./menuReducer";
import modalReducer from "./modalReducer";
import productReducer from "./productReducer";
import vendorReducer from "./vendorReducer";

import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        product: productReducer,
        menu: menuReducer,
        vendor: vendorReducer,
        collection: collectionReducer,
        cart: cartReducer,
        modal: modalReducer,
    },
});
