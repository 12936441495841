import React, { FC, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ProductResponse } from "src/types/service";

import { Box, Container, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import ProductDescription from "src/components/products/ProductDescription";
import ProductIntro from "src/components/products/ProductIntro";
import RelatedProducts from "src/components/products/RelatedProducts";

import RecentProducts from "../products/RecentProducts";

import { addRecentProductId } from "src/store/productReducer";

const StyledTabs = styled(Tabs)(({ theme }) => ({
    marginTop: -25,
    marginBottom: 24,
    minHeight: 0,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    "& .inner-tab": {
        fontWeight: 600,
        minHeight: 40,
        textTransform: "capitalize",
    },
}));
interface IProductDetailProps {
    product: ProductResponse;
}
const ProductDetails: FC<IProductDetailProps> = ({ product }) => {
    const [selectedOption, setSelectedOption] = useState(0);
    //   const classes = useStyles()
    const [listRencentProduct, setListRencentProduct] = useState([]);
    const handleOptionClick = (_event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedOption(newValue);
    };

    let recentProductIds = useSelector((state: any) => state.product.recentProduct);
    const dispatch = useDispatch<any>();

    useEffect(() => {
        dispatch(addRecentProductId(product?.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product?.id]);

    useEffect(() => {
        const recentProductsWithoutSelf = recentProductIds.filter(
            (el: string) => el !== product?.id
        );
        setListRencentProduct(recentProductsWithoutSelf);
    }, [product?.id, recentProductIds]);

    return (
        <Container sx={{ my: "2rem", pb: "2rem" }}>
            {product ? <ProductIntro product={product} /> : null}
            <StyledTabs
                sx={{
                    marginTop: "54px",
                    maxWidth: "80%",
                    marginInline: "auto",
                }}
                value={selectedOption}
                onChange={handleOptionClick}
                indicatorColor="primary"
                textColor="primary">
                {product?.description && (
                    <Tab
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            paddingBottom: "40px",
                        }}
                        className="inner-tab"
                        label="Mô tả sản phẩm"
                    />
                )}
            </StyledTabs>
            <Box
                mb={6}
                sx={{
                    maxWidth: "600px",
                    marginInline: "auto",
                }}>
                <ProductDescription description={product?.description} />
            </Box>
            <RelatedProducts collectionId={product?.collection_ids[0]} />
            {listRencentProduct.length && <RecentProducts productIds={listRencentProduct} />}
        </Container>

        // <RelatedProducts /> */}
    );
};

export default ProductDetails;
