import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ProductResponse } from "src/types/service";
import { formatVND } from "src/util";
import { mappingValueAttributeVariant } from "src/util/common";

import { Add, Remove } from "@mui/icons-material";
import { Alert, Box, Grid, Snackbar, Stack, useMediaQuery, useTheme } from "@mui/material";
import Discount from "src/components/discount/Discount";
import MyButton from "src/components/MyButton";
import MyImage from "src/components/MyImage";
import { H1, H2, H3, H6 } from "src/components/Typography";

import FlexBox from "../FlexBox";
import ProductVariantButton from "../ProductVariantButton";

import noImage from "src/assets/images/no_image.jpg";
import { CartItem, changeCartAmount, updateIsOpenMiniCart } from "src/store/cartReducer";

export interface ProductIntroProps {
    product: ProductResponse;
}

const ProductIntro: React.FC<ProductIntroProps> = ({ product }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const [qty, setQty] = useState(1);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [selectedImage, setSelectedImage] = useState(0);
    const [selectedVariant, setSelectedVariant] = useState<ProductResponse["variants"][number]>(
        product.variants[0]
    );
    const [cartItem, setCartItem] = useState<CartItem | undefined>(undefined);
    let cartList = useSelector((state: any) => state.cart.cartList);

    useEffect(() => {
        setCartItem(cartList.find((item: CartItem) => item.id === product?.id));
    }, [cartList, product?.id]);
    const dispatch = useDispatch<any>();

    const handleCartAmountChange = useCallback(
        (amount: number, item: ProductResponse) => () => {
            setOpen(true);
            setQty(1);
            dispatch(
                changeCartAmount({
                    id: selectedVariant.id,
                    name: selectedVariant.attributes.map(mappingValueAttributeVariant).join(" / "),
                    productName: item?.name,
                    productId: item?.id,
                    qty: amount,
                    price: selectedVariant.retail_price,
                    imgUrl: item?.images && item?.images[0]?.url,
                })
            );
            dispatch(updateIsOpenMiniCart(true));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedVariant.id]
    );

    return (
        <Box width="100%">
            <Grid container spacing={3} justifyContent="space-around">
                <Grid item md={4} xs={12} alignItems="center">
                    <Box>
                        <FlexBox
                            sx={{
                                marginBottom: "24px",
                            }}
                            justifyContent="center"
                            mb={6}>
                            <MyImage
                                src={
                                    (product?.images && product?.images[selectedImage].url) ||
                                    noImage
                                }
                                alt={product?.name}
                                loading="eager"
                                sx={{
                                    objectFit: "contain",
                                    width: 300,
                                    height: 300,
                                    ...(isMobile && {
                                        height: 150,
                                        width: 150,
                                    }),
                                }}
                            />
                        </FlexBox>
                    </Box>
                </Grid>

                <Grid item md={5} xs={12} alignItems="center">
                    <H1 mb={2}>{product?.name}</H1>

                    <FlexBox alignItems="center" mb={2}>
                        <Box>Nhà cung cấp:</Box>
                        <H6 ml={1}>{product?.vendor?.name}</H6>
                    </FlexBox>

                    <Box
                        mb={3}
                        sx={{
                            marginBottom: "16px",
                        }}>
                        <H2 color="primary.main" mb={1.5} lineHeight="1">
                            {formatVND(selectedVariant.retail_price || 0)}
                        </H2>
                        <Box color="inherit">Còn hàng</Box>
                    </Box>
                    <Stack spacing={1}>
                        <FlexBox alignItems="center">
                            <MyButton
                                sx={{ p: "9px" }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                disabled={qty === 1}
                                onClick={() => setQty(qty - 1)}>
                                <Remove fontSize="small" />
                            </MyButton>
                            <H3 fontWeight="600" mx={2.5}>
                                {qty.toString().padStart(2, "0")}
                            </H3>
                            <MyButton
                                sx={{ p: "9px" }}
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={() => setQty(qty + 1)}>
                                <Add fontSize="small" />
                            </MyButton>
                        </FlexBox>
                        <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
                            {product
                                ? product.variants.map((pVariant) => (
                                      <ProductVariantButton
                                          key={pVariant.id}
                                          variant={pVariant}
                                          onClick={() => {
                                              if (pVariant.id !== selectedVariant.id) {
                                                  setSelectedVariant(pVariant);
                                              }
                                          }}
                                          isSelected={selectedVariant.id === pVariant.id}
                                      />
                                  ))
                                : null}
                        </Stack>
                        <MyButton
                            variant="contained"
                            color="primary"
                            sx={{
                                mb: "36px",
                                px: "1.75rem",
                                height: "40px",
                            }}
                            onClick={handleCartAmountChange(qty, product)}>
                            Thêm vào giỏ hàng
                        </MyButton>
                    </Stack>
                </Grid>
                <Grid item md={3} xs={12} alignItems="center">
                    <Discount />
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={2000}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    Thêm vào giỏ hàng thành công
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductIntro;
