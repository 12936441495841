import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "src/components/ScrollToTop/ScrollToTop";

import ProductPageByTag from "./views/ProductPageByTag/ProductPageByTag";

import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NavbarLayout from "src/layouts/NavbarLayout";
import Cart from "src/views/Cart";
import Collection from "src/views/Collection";
import DeliveryPolicy from "src/views/DeliveryPolicy";
import GeneralPage from "src/views/Home";
import InformationCompany from "src/views/InformationCompany";
import MyModal from "src/views/Modal";
import NotFoundPage from "src/views/NotFound";
import PaymentGuide from "src/views/PaymentGuide";
import PrivacyPolicy from "src/views/PrivacyPolicy";
import DetailPage from "src/views/ProductDetail";
import ProductSearch from "src/views/ProductSearch";
import ReturnPolicy from "src/views/ReturnPolicy";
import TermsAndServices from "src/views/TermsAndServices";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<NavbarLayout />}>
                        <Route index element={<GeneralPage />} />
                        <Route path="/product/:id" element={<DetailPage />} />
                        <Route path="/product/search/:name" element={<ProductSearch />} />
                        <Route path="/collection/:id" element={<Collection />} />
                        <Route path="/products/tag/:id" element={<ProductPageByTag />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/pages/dieu-khoan-dich-vu" element={<TermsAndServices />} />
                        <Route path="/pages/chinh-sach-bao-mat" element={<PrivacyPolicy />} />
                        <Route path="/pages/chinh-sach-doi-tra" element={<ReturnPolicy />} />
                        <Route path="/pages/phuong-thuc-van-chuyen" element={<DeliveryPolicy />} />
                        <Route
                            path="/pages/thong-tin-huong-dan-thanh-toan"
                            element={<PaymentGuide />}
                        />
                        <Route path="/pages/lien-he" element={<InformationCompany />} />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <MyModal />
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
