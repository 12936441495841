import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const TermsAndServices = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Điều Khoản Dịch Vụ</Typography>

                            <Typography variant="h6">1. Giới thiệu</Typography>
                            <Typography variant="body1">
                                Chào mừng quý khách hàng đến với website chúng tôi.
                            </Typography>
                            <Typography variant="body1">
                                Khi quý khách hàng truy cập vào trang website của chúng tôi có nghĩa
                                là quý khách đồng ý với các điều khoản này. Trang web có quyền thay
                                đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Điều khoản
                                mua bán hàng hóa này, vào bất cứ lúc nào. Các thay đổi có hiệu lực
                                ngay khi được đăng trên trang web mà không cần thông báo trước. Và
                                khi quý khách tiếp tục sử dụng trang web, sau khi các thay đổi về
                                Điều khoản này được đăng tải, có nghĩa là quý khách chấp nhận với
                                những thay đổi đó.
                            </Typography>
                            <Typography variant="body1">
                                Quý khách hàng vui lòng kiểm tra thường xuyên để cập nhật những thay
                                đổi của chúng tôi.
                            </Typography>

                            <Typography variant="h6">2. Hướng dẫn sử dụng website</Typography>
                            <Typography variant="body1">
                                Khi vào web của chúng tôi, khách hàng phải đẩm bảo đủ 18 tuổi, hoặc
                                truy cập dưới sự giám sát của cha mẹ hay người giám hộ hợp pháp.
                                Khách hàng đảm bảo có đầy đủ hành vi dân sự để thực hiện các giao
                                dịch mua bán hàng hóa theo quy định hiện hành của pháp luật Việt Nam
                            </Typography>
                            <Typography variant="body1">
                                Trong suốt quá trình đăng ký, quý khách đồng ý nhận email quảng cáo
                                từ website. Nếu không muốn tiếp tục nhận mail, quý khách có thể từ
                                chối bằng cách nhấp vào đường link ở dưới cùng trong mọi email quảng
                                cáo
                            </Typography>

                            <Typography variant="h6">3. Thanh toán an toàn và tiện lợi</Typography>
                            <Typography variant="body1">
                                Người mua có thể tham khảo các phương thức thanh toán sau đây và lựa
                                chọn áp dụng phương thức phù hợp:
                            </Typography>
                            <Typography variant="body1">
                                <b>Cách 1: </b>
                                Thanh toán trực tiếp (người mua nhận hàng tại địa chỉ người bán)
                                <br />
                                <b>Cách 2: </b>
                                Thanh toán sau (COD - giao hàng và thu tiền tận nơi)
                                <br />
                                <b>Cách 3: </b>
                                Thanh toán online qua thẻ tín dụng, chuyển khoản
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default TermsAndServices;
