import { Container, Grid } from "@mui/material";

import CategorySectionHeader from "../CategorySectionHeader";
import Rating from "../icons/Rating";
import ProductCard1 from "../product-cards/ProductCard1";
import ProductCardSkeleton from "../skeleton/ProductCardSkeleton";

import useProducts from "src/hooks/useProducts";

const SectionMainListProduct = ({
    collectionIds,
    title,
    tagId,
}: {
    tagId: string;
    collectionIds: string[];
    title: string;
}) => {
    const { data: dataProducts, isLoading } = useProducts({ idCollections: collectionIds });

    return dataProducts && dataProducts.items.length ? (
        <Container sx={{ mb: "70px" }}>
            <CategorySectionHeader
                icon={<Rating fontSize="medium" />}
                title={title}
                seeMoreLink={`/products/tag/${tagId}`}
            />
            <Grid container spacing={3}>
                {isLoading
                    ? [...Array(6)].map((_, index) => (
                          <Grid item lg={3} md={4} sm={6} xs={6} key={index}>
                              <ProductCardSkeleton key={index} />
                          </Grid>
                      ))
                    : dataProducts.items.map((product, ind) => (
                          <Grid item lg={3} md={4} sm={6} xs={6} key={ind}>
                              <ProductCard1 product={product} />
                          </Grid>
                      ))}
            </Grid>
        </Container>
    ) : null;
};

export default SectionMainListProduct;
