import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Featured from "src/components/icons/Featured";

import Carousel from "../carousel/Carousel";
import CategorySectionCreator from "../CategorySectionCreator";
import ProductCard1 from "../product-cards/ProductCard1";

import useProducts from "src/hooks/useProducts";
import useWindowSize from "src/hooks/useWindowSize";

const SpecialCategory = ({
    collectionIds,
    title,
    tagId,
}: {
    tagId: string;
    collectionIds: string[];
    title: string;
}) => {
    const [visibleSlides, setVisibleSlides] = useState(4);
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < 500) setVisibleSlides(1);
        else if (width < 650) setVisibleSlides(2);
        else if (width < 950) setVisibleSlides(3);
        else setVisibleSlides(4);
    }, [width]);

    const { data: dataProducts } = useProducts({
        idCollections: collectionIds,
    });

    return dataProducts && dataProducts.items.length ? (
        <CategorySectionCreator
            icon={<Featured color="primary" />}
            title={title}
            seeMoreLink={`/products/tag/${tagId}`}>
            <Box mt={-0.5} mb={-0.5}>
                <Carousel totalSlides={dataProducts.items.length} visibleSlides={visibleSlides}>
                    {dataProducts
                        ? dataProducts.items.map((product, ind) => (
                              <Box height={"100%"} py={0.5} key={ind}>
                                  <ProductCard1 product={product} />
                              </Box>
                          ))
                        : null}
                </Carousel>
            </Box>
        </CategorySectionCreator>
    ) : null;
};

export default SpecialCategory;
