import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ProductResponse } from "src/types/service";

import NewProductDetail from "src/components/NewProductDetail/NewProductDetail";

import { getProductByProductId } from "src/store/productReducer";

const DetailPage = () => {
    let { id } = useParams();
    let productById = useSelector((state: any) => state.product.productById);

    const dispatch = useDispatch<any>();
    const [currentProduct, setCurrentProduct] = useState<ProductResponse | null>(null);

    useEffect(() => {
        dispatch(getProductByProductId(id as string));
        setCurrentProduct(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    useEffect(() => {
        let currentProduct = productById[id as keyof typeof productById];

        setCurrentProduct(currentProduct);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productById]);

    return (
        <div className="container">
            {currentProduct ? <NewProductDetail product={currentProduct} /> : null}
        </div>
    );
};

export default DetailPage;
