import { transformData } from "src/util/menu";

import ArrowRight from "@mui/icons-material/ArrowRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, Container, MenuItem, styled } from "@mui/material";
import FlexBox from "src/components/FlexBox";
import MyCard from "src/components/MyCard";
import NavLink from "src/components/nav-link/NavLink";

import useMenus from "src/hooks/useMenus";
import { IMenuChildren } from "src/store/menuReducer";

// component props interface
export interface NavbarProps {
    navListOpen?: boolean;
    hideCategories?: boolean;
}
// NavList props interface

// const common css style
const navLinkStyle = {
    cursor: "pointer",
    transition: "color 150ms ease-in-out",
    "&:hover": {
        color: "primary.main",
    },
    "&:last-child": {
        marginRight: "0",
    },
};
// style components
const StyledNavLink = styled(NavLink)(() => ({ ...navLinkStyle }));

const ParentNav = styled(Box)(({ theme }) => ({
    "&:hover": {
        color: theme.palette.primary.main,
        "& > .parent-nav-item": {
            display: "block",
        },
    },
}));

const ParentNavItem = styled(Box)(() => ({
    display: "none",
    position: "absolute",
    top: 0,
    left: "100%",
    zIndex: 5,
}));

const NavBarWrapper = styled(MyCard)(({ theme }) => ({
    display: "block",
    position: "relative",
    height: "60px",
    borderRadius: "0px",
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const InnerContainer = styled(Container)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
}));

const renderNestedNav = (list: IMenuChildren[], isRoot = false) => {
    return list?.map((nav: IMenuChildren) => {
        if (isRoot) {
            if (nav.collection && !nav.sub_menus?.length)
                return (
                    <StyledNavLink
                        style={{
                            marginRight: "2em",
                        }}
                        href={`/collection/${nav.collection.id}`}
                        key={nav.collection.title}>
                        {nav.collection.title}
                    </StyledNavLink>
                );
            if (nav.sub_menus?.length)
                return (
                    <FlexBox
                        position="relative"
                        flexDirection="row"
                        alignItems="center"
                        key={nav.collection.title}
                        sx={{
                            marginRight: "2em",
                            "&:last-child": {
                                marginRight: "0",
                            },
                            "&:hover": {
                                "& > .child-nav-item": {
                                    display: "block",
                                },
                            },
                        }}>
                        <StyledNavLink
                            href={`/collection/${nav.collection.id}`}
                            key={nav.collection.title}>
                            <Box sx={navLinkStyle}>{nav.collection.title}</Box>
                        </StyledNavLink>
                        <ExpandMore sx={{ color: "rgb(174, 180, 190)" }} fontSize="small" />
                        <Box
                            className="child-nav-item"
                            sx={{
                                display: "none",
                                position: "absolute",
                                left: 0,
                                top: "100%",
                                zIndex: 5,
                            }}>
                            <MyCard
                                sx={{
                                    mt: "1.25rem",
                                    py: "0.5rem",
                                    minWidth: "230px",
                                }}
                                elevation={3}>
                                {renderNestedNav(nav.sub_menus)}
                            </MyCard>
                        </Box>
                    </FlexBox>
                );
        } else {
            if (nav.collection.id && !nav.sub_menus?.length) {
                return (
                    <NavLink href={`/collection/${nav.collection.id}`} key={nav.collection.id}>
                        <MenuItem>{nav.collection.title}</MenuItem>
                    </NavLink>
                );
            }
            if (nav.sub_menus?.length) {
                return (
                    <ParentNav position="relative" minWidth="230px" key={nav.collection.title}>
                        <NavLink href={`/collection/${nav.collection.id}`} key={nav.collection.id}>
                            <MenuItem color="grey.700">
                                <Box flex="1 1 0" component="span">
                                    {nav.collection.title}
                                </Box>
                                <ArrowRight fontSize="small" />
                            </MenuItem>
                        </NavLink>
                        <ParentNavItem className="parent-nav-item" pl={1}>
                            <MyCard sx={{ py: "0.5rem", minWidth: "230px" }} elevation={3}>
                                {renderNestedNav(nav.sub_menus)}
                            </MyCard>
                        </ParentNavItem>
                    </ParentNav>
                );
            }
        }
    });
};

const Navbar = () => {
    const { data: menuData } = useMenus();

    return (
        <NavBarWrapper elevation={2} hoverEffect={false}>
            <InnerContainer sx={{ justifyContent: "center" }}>
                <FlexBox flexWrap="wrap" justifyContent="center" alignItems="cen">
                    {menuData
                        ? renderNestedNav(transformData(menuData.menu_items).sub_menus, true)
                        : undefined}
                </FlexBox>
            </InnerContainer>
        </NavBarWrapper>
    );
};

export default Navbar;
