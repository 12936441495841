import React from "react";

import { useSelector } from "react-redux";

import Close from "@mui/icons-material/Close";
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FlexBox from "src/components/FlexBox";
import ShoppingBagOutlined from "src/components/icons/ShoppingBagOutlined";
import MyButton from "src/components/MyButton";
import MyIconButton from "src/components/MyIconButton";

import IconDiscount from "../icons/Discount";
import { H4, Paragraph } from "../Typography";

import { CartItem } from "src/store/cartReducer";

const Discount: React.FC = () => {
    const { palette } = useTheme();
    let discountList: CartItem[] = [];

    return (
        <Box height="calc(100% + 30px)" border="1px solid" borderColor="grey.400">
            <Box
                padding="20px"
                overflow="hidden"
                height={`calc(100% - ${discountList.length ? "80px" : "0px"})`}>
                <FlexBox
                    flexDirection="column"
                    alignItems="center"
                    height="74px"
                    color="secondary.main">
                    <FlexBox width="100%" alignItems="center" position="relative">
                        <IconDiscount
                            sx={{
                                alignSelf: "flex-start",
                                fill: "none !important",
                                fontSize: 32,
                            }}
                        />
                        <H4
                            sx={{
                                width: "100%",
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                textAlign: "center",
                            }}
                            color="primary.main">
                            ƯU ĐÃI ĐẶC BIỆT
                        </H4>
                    </FlexBox>
                    <FlexBox alignItems="center">
                        <Paragraph color="primary">Hãy sử dụng để tiết kiệm hơn nhé</Paragraph>
                    </FlexBox>
                </FlexBox>

                <Divider />

                {!discountList.length && (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="calc(100% - 74px)">
                        <Box
                            component="p"
                            mt={2}
                            color="grey.600"
                            textAlign="center"
                            maxWidth="200px">
                            Hiện bạn đang không có voucher nào
                        </Box>
                    </FlexBox>
                )}
                {discountList.map((item: CartItem) => (
                    <FlexBox
                        flexDirection="column"
                        alignItems="center"
                        py={2}
                        px={2.5}
                        borderBottom={`1px solid ${palette.divider}`}
                        key={item.id}>
                        <FlexBox justifyContent="space-between" width="100%"></FlexBox>
                    </FlexBox>
                ))}
            </Box>

            {!!discountList.length && (
                <Box p={2.5} paddingBottom={0}>
                    <MyButton
                        color="primary"
                        sx={{
                            height: "40px",
                        }}
                        fullWidth>
                        Xem thêm
                    </MyButton>
                </Box>
            )}
        </Box>
    );
};

export default Discount;
