import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const PaymentGuide = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Thông Tin Hướng Dẫn Thanh Toán</Typography>

                            <Typography variant="body1">
                                Khách hàng có thể mua sắm trực tuyến thuận lợi hơn nhờ hình thức
                                thanh toán linh hoạt, nhanh chóng.
                            </Typography>

                            <Typography variant="h6">
                                1. Thanh toán qua Tài khoản ngân hàng
                            </Typography>
                            <Typography variant="body1">
                                - Là phương thức thanh toán trực tuyến nhanh chóng, tiện lợi cho
                                những khách mua hàng online.
                            </Typography>
                            <Typography variant="body1">
                                - Sau khi đặt hàng và nhận được thông tin xác nhận từ phía nhà bán
                                hàng, khách hàng vui lòng chuyển tiền đến tài khoản của chúng tôi
                                theo thông tin dưới đây. Khi chuyển khoản ghi rõ nội dung chuyển
                                khoản theo cú pháp [Mã đơn hàng]_[SĐT] VD: #100001_035924594
                            </Typography>
                            <Typography variant="body1">- STK: XXXXXXXXXXX</Typography>
                            <Typography variant="body1">- Chủ tài khoản: Nguyễn Công Tú</Typography>
                            <Typography variant="body1">- Ngân hàng: XXXXXXXXXXXX</Typography>
                            <Typography variant="body1">
                                - Sau khi nhận được tiền, chúng tôi sẽ liên hệ để xác nhận và gửi
                                hàng cho quý khách.
                            </Typography>
                            <Typography variant="body1">
                                - Thời gian nhận hàng: 2 - 5 ngày, kể từ ngày xác nhận đơn hàng.
                            </Typography>

                            <Typography variant="h6">Thanh toán sau khi nhận hàng (COD)</Typography>

                            <Typography variant="body1">
                                Ngoài ra, để hỗ trợ khách hàng online, nhasachkiengiang.com Việt Nam
                                cũng áp dụng hình thức thanh toán sau (COD), nhận hàng và trả tiền
                                tại nhà. Với hình thức này, bên cạnh phí vận chuyển, quý khách có
                                thể phải thanh toán thêm phí thu hộ tiền hàng.
                            </Typography>
                            <Typography variant="body1">
                                Sau khi Quý khách đặt hàng trên website, chúng tôi sẽ liên hệ lại để
                                xác nhận đơn hàng, bao gồm tổng số lượng, giá trị đơn hàng, chi phí
                                vận chuyển mà khách hàng phải chịu.
                            </Typography>
                            <Typography variant="body1">
                                Quý khách hàng thanh toán tiền trực tiếp cho nhân viên giao hàng.
                            </Typography>
                            <Typography variant="body1">
                                Xin chân thành cảm ơn Quý khách hàng đã tin tưởng Nhà Sách Kiên
                                Giang
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PaymentGuide;
