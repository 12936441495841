import { PagingResponse } from "src/types/paging";
import { ProductResponse, QueryDataResponse } from "src/types/service";
import { getData } from "src/util/service";

import { useQuery } from "@tanstack/react-query";

export interface ProductsServiceResponse {
    items: ProductResponse[];
    paging: PagingResponse;
}

const useProducts = ({
    idCollections,
    limit = 8,
}: {
    idCollections?: string[];
    limit?: number;
}) => {
    return useQuery<QueryDataResponse<ProductResponse>>({
        staleTime: Infinity,
        cacheTime: 0,
        queryKey: [
            "products",
            {
                collection_ids: idCollections,
                limit,
            },
        ],
        queryFn: () =>
            getData({
                path: "/products",
                config: {
                    params: {
                        limit,
                        offset: 0,
                        collection_ids: idCollections ? `["${idCollections.join(`", "`)}"]` : [],
                    },
                },
            }),
    });
};

export default useProducts;
