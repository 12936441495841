import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Box, Container, useTheme } from "@mui/material";
import Carousel from "src/components/carousel/Carousel";
import SectionFeaturedCategory from "src/components/FeaturedCategroy/SectionFeaturedCategory";
import MainListProduct from "src/components/MainListProduct/MainListProduct";
import BannerSkeleton from "src/components/skeleton/BannerSkeleton";
import SpecialCategory from "src/components/SpecialCategory/SpecialCategory";
import Subscription from "src/components/Subscription/Subscription";

import imgSlide1 from "src/assets/images/slide_1_img.jpeg";
import imgSlide2 from "src/assets/images/slide_2_img.jpeg";
import useTags from "src/hooks/useTags";

const useStyles = makeStyles()((theme) => ({
    sectionFeaturedCategroy: {
        paddingTop: "70px",
        [theme.breakpoints.down("md")]: {
            paddingTop: "45px",
        },
    },
    sectionTab: {
        paddingBottom: "45px",
    },
    sectionSpecialCategory: {
        paddingTop: "70px",
        paddingBottom: "70px",
        background: "#fff6f6",
    },
}));

const GeneralPage = () => {
    const theme = useTheme();
    const { classes } = useStyles();

    const { data: tagsData } = useTags();

    return (
        <>
            <Box bgcolor="white" mb={7.5}>
                <Container
                    sx={{
                        maxWidth: "100% !important",
                        padding: "0 !important",
                        ".carousel__dot-group": {
                            position: "absolute",
                            bottom: "5%",
                            left: "50%",
                            transform: "translateX(-50%)",
                        },
                    }}>
                    <Carousel
                        totalSlides={2}
                        visibleSlides={1}
                        infinite={true}
                        autoPlay={false}
                        showDots={true}
                        showArrow={false}
                        spacing="0px">
                        <Link to="/collection/all">
                            <BannerSkeleton src={imgSlide1} />
                        </Link>
                        <Link to="/collection/all">
                            <BannerSkeleton src={imgSlide2} />
                        </Link>
                    </Carousel>
                </Container>
            </Box>

            <SectionFeaturedCategory />
            {tagsData
                ? tagsData.items.map((tagData, indexTag) => {
                      if (indexTag === 0 || indexTag % 2 === 0) {
                          return (
                              <MainListProduct
                                  key={tagData.tag_id}
                                  tagId={tagData.tag_id}
                                  title={tagData.tag_name}
                                  collectionIds={tagData.collection_ids}
                              />
                          );
                      } else {
                          return (
                              <section
                                  className={classes.sectionSpecialCategory}
                                  key={tagData.tag_id}>
                                  <SpecialCategory
                                      tagId={tagData.tag_id}
                                      title={tagData.tag_name}
                                      collectionIds={tagData.collection_ids}
                                  />
                              </section>
                          );
                      }
                  })
                : null}
            <Box
                sx={{
                    background: "transparent",
                    backgroundImage: `linear-gradient(180deg, #fff6f6 40%, ${theme.palette.primary.main} 0%)`,
                }}>
                <Container>
                    <Subscription />
                </Container>
            </Box>
        </>
    );
};

export default GeneralPage;
