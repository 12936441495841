import { Link } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Box, Container, Paper, SvgIcon, Typography } from "@mui/material";
import MyButton from "src/components/MyButton";

import useModal from "src/hooks/useModal";

export const CheckoutSuccess = () => {
    const { onDismiss } = useModal();
    return (
        <Box
            sx={{
                p: 3,
            }}>
            <Container maxWidth="sm">
                <Paper
                    elevation={12}
                    sx={{
                        p: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Avatar
                        sx={{
                            backgroundColor: "success.lightest",
                            color: "success.main",
                            mb: 2,
                        }}>
                        <SvgIcon>
                            <CheckIcon />
                        </SvgIcon>
                    </Avatar>
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                        }}>
                        Mua hàng thành công
                    </Typography>
                    <Link to="/">
                        <MyButton
                            sx={{
                                background: (theme) => theme.palette.primary.main,
                                color: "#fff",
                                mt: 4,
                                "&:hover": {
                                    background: "#E3364E",
                                },
                            }}
                            fullWidth
                            onClick={onDismiss}
                            size="large">
                            Về trang chủ
                        </MyButton>
                    </Link>
                </Paper>
            </Container>
        </Box>
    );
};

export default CheckoutSuccess;
