import { localKey } from "src/constants";
import { apiConfig, processAction } from "src/https";
import { VariantResponse } from "src/types/service";
import { buildQueryString } from "src/util";

import { IVendor } from "./vendorReducer";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getObjectData, setObjectData } from "src/util/localStore";

const nameReducer = "product";
const { apiProduct } = apiConfig;

interface IImageProduct {
    url: string;
}
export interface IProduct {
    id: string;
    img: string[] | string;
    images: IImageProduct[];
    publisher: string;
    name: string;
    slug: string;
    price: number;
    status: string;
    description?: string;
    vendor_id: string;
    category_id: string;
    collection_ids: string[];
    vendor: IVendor;
    variants: VariantResponse[];
}

interface IStates {
    productById: {
        [id: string]: IProduct;
    };
    listProductByCollectionId: {
        [id: string]: IProduct[];
    };
    recentProduct: string[];
}

interface IQueryProduct {
    limit: number;
    offset: number;
    product_ids?: string;
    collections_ids?: string;
    from_price?: number;
    to_price?: number;
}

// --- Tạo thunk ---
export const getProductByProductId = createAsyncThunk(
    `${nameReducer}/getProductByProductId`,
    async (productId: string) => {
        const query = buildQueryString({
            limit: 1,
            offset: 0,
            product_ids: JSON.stringify([productId]),
        } as Pick<IQueryProduct, "limit" | "offset" | "product_ids">);
        const response = await processAction({
            path: apiProduct.getProduct.path + query,
            method: apiProduct.getProduct.method,
        });
        const items: IProduct[] = response?.data?.items || [];
        return items;
    }
);

interface IPayloadGetProductByCollectionId {
    collectionId: string;
    payload: IQueryProduct;
}

export const getProductByCollectionId = createAsyncThunk(
    `${nameReducer}/getProductByCollectionId`,
    async (payload: IPayloadGetProductByCollectionId) => {
        const query = buildQueryString({
            ...payload.payload,
            collection_ids: JSON.stringify([payload.collectionId]),
        } as IQueryProduct);
        const response = await processAction({
            path: apiProduct.getProduct.path + query,
            method: apiProduct.getProduct.method,
        });
        const items: IProduct[] = response?.data?.items || [];
        return {
            collectionId: payload.collectionId,
            items,
        };
    }
);

const productSlice = createSlice({
    name: nameReducer,
    initialState: {
        productById: {},
        listProductByCollectionId: {},
        recentProduct: getObjectData(localKey.recentProduct) || [],
    } as IStates,
    reducers: {
        addRecentProductId(state, action: PayloadAction<string>) {
            let index = state.recentProduct.findIndex((item) => item === action.payload);
            if (index !== -1) {
                state.recentProduct.splice(index, 1);
            }
            state.recentProduct.unshift(action.payload);
            state.recentProduct = state.recentProduct.filter((product) => !!product).slice(0, 9); // get all 9 element first because not show current product
            setObjectData(localKey.recentProduct, state.recentProduct);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductByProductId.fulfilled, (state, action) => {
                action.payload.forEach((product) => {
                    state.productById[product.id] = product;
                });
            })
            .addCase(getProductByCollectionId.fulfilled, (state, action) => {
                const { collectionId, items } = action.payload;
                state.listProductByCollectionId[collectionId] = items;
            });
    },
});

export const { addRecentProductId } = productSlice.actions;
export default productSlice.reducer;
