import { useState } from "react";

import { useNavigate } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";

import { MenuItem } from "src/types/menuItem";

const SideNavItem = ({
    item: { id, name, sub_menus, collection_id },
    level,
    closeNavMobile,
}: {
    item: MenuItem;
    level: number;
    closeNavMobile: () => void;
}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);

        if (!sub_menus.length) {
            navigate(`/collection/${collection_id}`);
            closeNavMobile();
        }
    };

    return (
        <>
            <ListItemButton key={id} onClick={handleClick} sx={{ pl: 4 * level }}>
                <ListItemText primary={name} />
                {sub_menus.length ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItemButton>
            {sub_menus.length ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {sub_menus.map((subItem) => (
                            <SideNavItem
                                item={subItem}
                                key={subItem.id}
                                level={level + 1}
                                closeNavMobile={closeNavMobile}
                            />
                        ))}
                    </List>
                </Collapse>
            ) : null}
        </>
    );
};

export default SideNavItem;
