import { Box, Container, Grid, Stack, Typography } from "@mui/material";

const DeliveryPolicy = () => {
    return (
        <Box bgcolor="white">
            <Container
                sx={{
                    my: "2rem",
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <Stack spacing={2} my={4}>
                            <Typography variant="h4">Chính Sách Giao Hàng & Kiểm Hàng</Typography>

                            <Typography variant="h6">1. Chính Sách Giao Hàng</Typography>
                            <Typography variant="body1">
                                nhasachkiengiang.com cung cấp dịch vụ giao hàng toàn quốc, gửi hàng
                                tận nơi đến địa chỉ cung cấp của Quý khách. Thời gian giao hàng dự
                                kiến phụ thuộc vào kho có hàng và địa chỉ nhận hàng của Quý khách.
                            </Typography>
                            <Typography variant="body1">
                                Với đa phần đơn hàng, nhasachkiengiang.com cần vài giờ làm việc để
                                kiểm tra thông tin và đóng gói hàng. Nếu các sản phẩm đều có sẵn
                                hàng, nhasachkiengiang.com sẽ nhanh chóng bàn giao cho đối tác vận
                                chuyển. Nếu đơn hàng có sản phẩm sắp phát hành, nhasachkiengiang.com
                                sẽ ưu tiên giao những sản phẩm có hàng trước cho Quý khách hàng.
                            </Typography>
                            <Typography variant="body1">
                                Mọi thông tin bạn nhập trên website sẽ được lưu trữ để sử dụng cho
                                mục đích phản hồi yêu cầu của khách hàng, đưa ra những gợi ý phù hợp
                                cho từng khách hàng khi mua sắm tại website, nâng cao chất lượng
                                hàng hóa dịch vụ và liên lạc với khách hàng khi cần thiết.
                            </Typography>
                            <Typography variant="body1">
                                Lưu ý: Ngày làm việc là từ thứ hai đến thứ sau, không tính thứ bảy,
                                chủ nhật và ngày nghỉ lễ, tết, nghỉ bù, và không bao gồm các tuyến
                                huyện đảo xa.
                            </Typography>

                            <Typography variant="h6">2. Một số lưu ý khi nhận hàng</Typography>

                            <Typography variant="body1">
                                Trước khi tiến hành giao hàng cho Quý khách, bưu tá của Đối tác vận
                                chuyển sẽ liên hệ qua số điện thoại của Quý khách trước khoảng 3 đến
                                5 phút để xác nhận giao hàng.
                            </Typography>
                            <Typography variant="body1">
                                Nếu Quý khách không thể có mặt trong đợt nhận hàng thứ nhất,
                                nhasachkiengiang.com sẽ cố gắng liên lạc lại thêm ít nhất 2 lần nữa
                                (trong 02 ca giao hàng khác nhau) để sắp xếp thời gian giao hàng,
                                Quý khách vui lòng để ý điện thoại để liên hệ được với bưu tá giao
                                hàng.
                            </Typography>
                            <Typography variant="body1">
                                Nếu qua 3 lần liên hệ giao hàng, nhasachkiengiang.com vẫn không thể
                                liên lạc được với Quý khách để giao hàng, nhasachkiengiang.com sẽ
                                thông báo cho Quý khách về việc hủy đơn hàng. Trong trường hợp Quý
                                khách đã thanh toán trước cho đơn hàng, Quý khách sẽ nhận lại tiền
                                vào tài khoản trong vòng 5 - 7 ngày làm việc, phụ thuộc vào tiến độ
                                xử lý của ngân hàng. Số tiền Quý khách nhận lại sẽ trừ lại chi phí
                                vận chuyển phát sinh từ việc giao hàng nhưng Quý khách không nhận
                                hàng.
                            </Typography>
                            <Typography variant="body1">
                                Trong trường hợp Quý khách không đồng ý nhận hàng với xuất phát
                                nguyên nhân từ hàng hóa của nhasachkiengiang.com không đảm bảo,
                                không đúng như mô tả, giao trễ so với cam kết,... Đơn hàng của Quý
                                khách sẽ được hoàn lại cho chúng tôi và được hủy trên hệ thống
                                nhasachkiengiang.com. Nếu Quý khách đã thanh toán trước cho đơn
                                hàng, Quý khách sẽ nhận lại tiền vào tài khoản trong vòng 5 - 7 ngày
                                làm việc, phụ thuộc vào tiến độ xử lý của ngân hàng. Số tiền Quý
                                khách nhận lại sẽ là toàn bộ số tiền đã thanh toán cho đơn hàng (bao
                                gồm phí vận chuyển).
                            </Typography>

                            <Typography variant="body1">
                                Trong trường hợp đơn hàng đang giao đến Quý khách có ngoại quan bên
                                ngoài hộp hàng hóa có dấu hiệu bị rách, móp, ướt, thủng, mất niêm
                                phong,…Quý khách vui lòng kiểm tra kỹ chất lượng sản phẩm bên trong
                                trước khi nhận hàng. Quý khách hoàn toàn có quyền từ chối nhận hàng
                                và báo về cho chúng tôi qua hotline 1900636467 để được hỗ trợ giao
                                lại đơn hàng mới hoặc hủy đơn hàng, hoàn tiền.
                            </Typography>
                            <Typography variant="body1">
                                Trong trường hợp Quý khách không có nhu cầu nhận hàng, Quý khách có
                                thể báo với bên vận chuyển và/hoặc CSKH (qua Hotline 1900636467) về
                                việc này. Đơn hàng của Quý khách sẽ được hoàn lại cho chúng tôi và
                                được hủy trên hệ thống. Trong trường hợp Quý khách đã thanh toán
                                trước cho đơn hàng, Quý khách sẽ nhận lại tiền vào tài khoản trong
                                vòng 5 - 7 ngày làm việc, phụ thuộc vào tiến độ xử lý của ngân hàng.
                                Số tiền Quý khách nhận lại sẽ trừ lại chi phí vận chuyển phát sinh
                                từ việc giao hàng nhưng Quý khách không nhận.
                            </Typography>

                            <Typography variant="body1">
                                nhasachkiengiang.com sẽ thông báo ngay đến Quý khách nếu có sự chậm
                                chễ về thời gian giao hàng so với thời gian dự kiến ở trên. Trong
                                phạm vi pháp luật cho phép, chúng tôi sẽ không chịu trách nhiệm cho
                                bất cứ tổn thất nào, các khoản nợ, thiệt hại hoặc chi phí phát sinh
                                từ việc giao hàng trễ. Trường hợp phát sinh chậm trễ trong việc giao
                                hàng, nếu Quý khách không còn nhu cầu nhận hàng,
                                nhasachkiengiang.com cam kết sẽ hỗ trợ Quý khách hủy đơn hàng, nếu
                                Quý khách đã thanh toán trước cho đơn hàng, Quý khách sẽ nhận lại
                                tiền vào tài khoản trong vòng 5 - 7 ngày làm việc, phụ thuộc vào
                                tiến độ xử lý của ngân hàng. Số tiền Quý khách nhận lại sẽ là toàn
                                bộ số tiền đã thanh toán cho đơn hàng (bao gồm phí vận chuyển).
                            </Typography>

                            <Typography variant="body1">
                                Sản phẩm được đóng gói theo tiêu chuẩn đóng gói của
                                nhasachkiengiang.com, nếu Quý khách có nhu cầu đóng gói đặc biệt
                                khác, vui lòng báo trước cho chúng tôi khi đặt hàng hàng và cho phép
                                chúng tôi được tính thêm phí cho nhu cầu đặc biệt này.
                            </Typography>

                            <Typography variant="body1">
                                Mọi thông tin về việc thay đổi sản phẩm hay hủy bỏ đơn hàng, đề nghị
                                Quý khách thông báo sớm để nhasachkiengiang.com có thể điều chỉnh
                                lại đơn hàng. Quý khách có thể liên hệ với chúng tôi qua số điện
                                thoại hotline: 1900636467 hoặc qua địa chỉ email
                                cskh@nhasachkiengiang.com.vn.
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default DeliveryPolicy;
