import { LocalKey } from "./type";

export const BASE_URL = `https://${process.env.REACT_APP_API_END_POINT}`;
export const MAIN_COLLECTION_ID = process.env.REACT_APP_MAIN_COLLECTION_ID;
export const SPECIAL_COLLECTION_ID = process.env.REACT_APP_SPECIAL_COLLECTION_ID;

const prefix: string = "nskg_";

export const localKey: LocalKey = {
    cartList: prefix + "cart_list",
    recentProduct: prefix + "recent_product",
};

export const modalType = {
    subscriptionSuccess: "subscriptionSuccess",
    checkoutSuccess: "checkoutSuccess",
};

export const phoneRegExp = /([\\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;
export const emailRegExp =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
